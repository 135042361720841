import React, { useEffect, useMemo, useState } from 'react'
import type { ColumnsType, TableProps } from 'antd/lib/table'
import {
  Button,
  Input,
  message,
  Table,
  Tag
} from 'antd'
import { Navigate, useNavigate } from 'react-router'
import {
  DropdownButton,
  PageHeader,
  Section
} from 'components'
import {
  TFindGroupParams,
  TGroup,
  useUserGroups
} from 'repositories'
import {
  usePagination,
  useAuth
} from 'hooks'
import { SorterResult } from 'antd/lib/table/interface'
import { Status } from 'utils/constants'
import { tableLocale } from 'utils/lang'
import { FilterContainer } from './styles'

type TGroupData = TGroup & {
  key: string
}

const columns: ColumnsType<TGroupData> = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
    sorter: true
  },
  {
    title: 'Status',
    dataIndex: 'groupStatus',
    key: 'groupStatus',
    sorter: true,
    render: (_, record) => {
      if (record.groupStatus === Status.ACTIVE) return <Tag color='green'>Ativo</Tag>

      return <Tag color='red'>Inativo</Tag>
    },
  }
]

const GroupList = () => {
  const [groups, setGroups] = useState<TGroupData[]>([])
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState('')

  const { roles: authRoles, hasRole } = useAuth()
  const repository = useUserGroups()
  const navigate = useNavigate()
  const pagination = usePagination()

  const requestGroup = async () => {
    const currentPage = pagination.data.current ?? 0
    const params: TFindGroupParams = {
      name: search,
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: pagination.data.pageSize ?? 1,
      sort,
    }

    const response = await repository.findGroupsByFilter(params)
    if (!response) return

    const mappedData: TGroupData[] = response.content.map(group => ({
      ...group,
      key: String(group.id)
    }))
    setGroups(mappedData)
    pagination.changeTotalPages(response.totalPages)
  }

  const handleChange: TableProps<TGroupData>['onChange'] = (paginationConfig, filters, sorter) => {
    pagination.onChangeTable(paginationConfig)
    const { field, order } = sorter as SorterResult<TGroupData>
    if (!field) return

    const dir = order === 'ascend' ? 'ASC' : 'DESC'
    setSort(`${field},${dir}`)
  }

  useEffect(() => {
    if (pagination.data.current) requestGroup()
  }, [pagination.data.current, pagination.data.pageSize, sort, search])

  const onClickGoToView = (id: number) => () => navigate(`/grupos-usuarios/visualizar/${id}`)
  const onClickGoToEdit = (id: number) => () => navigate(`/grupos-usuarios/editar/${id}`)
  const onClickGoToNew = () => navigate('/grupos-usuarios/novo')

  const onClickChangeStatus = async (record: TGroupData) => {
    const groupStatus = record.groupStatus === Status.ACTIVE
      ? Status.INACTIVE
      : Status.ACTIVE
    const data = {
      ...record,
      groupStatus,
      roles: record.userRoles.map(userRole => userRole.id)
    }

    const response = await repository.updateGroup(data)
    if (!response) return

    requestGroup()
    message.success('Status do grupo atualizado com sucesso!')
  }

  const updatedColumns = useMemo(() => [
    ...columns,
    {
      title: '',
      key: 'action',
      render: (_, record) => {
        const itemsMenu = [
          {
            key: '1',
            label: 'Visualizar',
            onClick: onClickGoToView(record.id)
          },
          {
            key: '2',
            label: 'Editar',
            disabled: !hasRole(authRoles?.group_edit),
            onClick: onClickGoToEdit(record.id)
          },
          {
            key: '3',
            label: record.groupStatus === Status.ACTIVE ? 'Inativar' : 'Ativar',
            disabled: !hasRole(authRoles?.group_edit),
            onClick: () => onClickChangeStatus(record)
          },
        ]
        return (
          <DropdownButton
            items={itemsMenu}
          />
        )
      },
    }
  ], [authRoles])

  const onSearch = (value: string) => {
    setSearch(value)
    pagination.onChange(1, pagination.data.pageSize ?? 1)
  }

  if (authRoles && !hasRole(authRoles?.group_view)) {
    return <Navigate to='/acesso-negado' />
  }

  return (
    <>
      <PageHeader
        title='Grupos de Usuários'
        breadcrumbItems={['Usuários', 'Grupos', 'Listar']}
      />
      <Section>
        <FilterContainer>
          <Input.Search
            placeholder='Pesquise pelo nome'
            onSearch={onSearch}
            enterButton
          />
          <Button
            onClick={onClickGoToNew}
            type='primary'
          >
            Novo
          </Button>
        </FilterContainer>
        <Table
          sticky
          size='middle'
          locale={tableLocale}
          onChange={handleChange}
          loading={repository.loading}
          columns={updatedColumns}
          dataSource={groups}
          pagination={pagination.data}
          showSorterTooltip={false}
        />
      </Section>
    </>
  )
}

export default GroupList
