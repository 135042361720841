import React, { useState } from 'react'
import { Avatar, Tooltip } from 'antd'
import { theme } from 'styles'
import { off } from 'process'

type TProps = {
  users: string[],
  rightOffset?: number
}

const colors = [
  theme.colors.GREEN,
  theme.colors.YELLOW,
  theme.colors.MEDIUM_GRAY
]

const Hover = ({ users, rightOffset }: TProps) => {
  return (
    <div style={{
      display: 'block',
      position: 'absolute',
      top: '100%',
      right: rightOffset ?? 0,
      width: 330,
      zIndex: 5,
      backgroundColor: 'white',
      border: '1px solid #f0f0f0',
      padding: 10
    }}
    >
      <h5><strong>Liderança do Projeto:</strong></h5>
      {users?.map((user, index) => {
        if (!user) return
        let nameAvatar = ""
        switch (index) {
          case 0:
            nameAvatar = "LC"
            break;
          case 1:
            nameAvatar = "DB"
            break;
          case 2:
            nameAvatar = "LO"
            break;
          case 3:
            nameAvatar = "DE"
            break;
        }

        switch (index) {
          case 0:
            user = `[Líder de Contas]: ${user}`
            break;
          case 1:
            user = `[Diretor da BU]: ${user}`
            break;
          case 2:
            user = `[Líder de Operações]: ${user}`
            break;
          case 3:
            user = `[Diretor Executivo]: ${user}`
            break;
        }

        return (
          <div style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
          >
            <Avatar
              style={{
                backgroundColor: colors[index],
                width: 32,
                marginRight: 5
              }}
            >
              {nameAvatar}
            </Avatar>
            <span>{user}</span>
          </div>
        )
      })}
    </div>
  )
}

const AvatarGroup = ({ users, rightOffset }: TProps) => {

  const [showUsers, setShowUsers] = useState(false)

  const containerStyle: React.CSSProperties = {
    position: 'relative',
    // Só aplica minWidth se rightOffset estiver definido (na página de visualização)
    ...(rightOffset !== undefined ? { minWidth: 120 } : {})
  }

  const hoverWrapperStyle: React.CSSProperties = {
    position: 'absolute',
    top: 35,
    right: rightOffset !== undefined ? rightOffset : 0,
    zIndex: 999
  }

  return (
    <div style={containerStyle}>
      <div
        onMouseOver={() => setShowUsers(true)}
        onMouseLeave={() => setShowUsers(false)}
      >
        <Avatar.Group>
          {users?.map((user, index) => {
            if (!user) return
            let nameAvatar = ""
            switch (index) {
              case 0:
                nameAvatar = "LC"
                break;
              case 1:
                nameAvatar = "DB"
                break;
              case 2:
                nameAvatar = "LO"
                break;
              case 3:
                nameAvatar = "DE"
                break;
            }

            return (
              <Avatar
                style={{
                  backgroundColor: colors[index]
                }}
              >
                {nameAvatar}
              </Avatar>
            )
          })}
          {showUsers ? (
            <div style={hoverWrapperStyle}>
              <Hover users={users} rightOffset={rightOffset} />
            </div>
          ) : null}
        </Avatar.Group>
      </div>
    </div>
  )

}

export default AvatarGroup
