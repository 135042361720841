import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
  padding: 20px 0;
  .ant-btn-question {
    
    &.new-question {
      background: #D14900;
      color: white !important;
      border-color: #D14900;
      transition: all 0.3s;
      img {
        animation: blink 1s forwards infinite;
      }
      &:hover {
        background: #D14900 !important;
        color: white !important;
        border-color: #D14900 !important;
        transform: scale(1.05);
      }
    }
    
  }
  @keyframes blink {
    0%{
      opacity: 1;
    }
    50%{
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`

export const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`