import React, { useEffect, useState } from "react";
import {
  usePurchaseOrder, useSuppliers,
} from "repositories";
import {
  Spin,
  Button,
  Empty,
  Pagination,
  Card,
  Divider,
  message,
} from "antd";
import {useFilters, usePagination } from "hooks";
import {FlexEndContainer, Section} from "components";
import {
  formatDateToServer, removeMaskCnpj,
} from "utils/helpers";
import {
  purchaseOrderStatus,
  supplierStatus,
  purchaseOrderStatusColor,
  purchaseOrderStatusTextColor,
  labelBackgroundColor,
  labelTextColor, purchaseOrderStatusTitleColor,
} from "utils/constants";

import {
  Box,
  ButtonBox,
  Content,
  Info,
  Label,
  AdditionalInfo,
  InfoOrder,
  StyledRow,
  YellowButton,
  ContentBox,
  ContentTitle,
  UserApproval
} from "./styles";

import FiltersForm from "./Components/FiltersForm";
import { useSearchParams } from "react-router-dom";
import { theme } from "styles";

import useEvaluation from "../../../repositories/useEvaluation";
import Popover from "../../Tracker/Components/Popover";
import EvaluationForm from "../Components/evaluationForm";


const OrderEvaluation = () => {
  const [orders, setOrders] = useState<any>();
  const [isVisibleFilterForm, setIsVisibleFilterForm] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isActiveApproval, setIsActiveApproval] = useState<boolean>();
  const [orderEvaluated, setOrderEvaluated] = useState<any>({});
  const [suppliers, setSuppliers] = useState<any>([]);

  const [disableRating, setDisableRating] = useState(false);
  const [justification, setJustification] = useState("");

  const filters = useFilters();
  const purchaseRepository = usePurchaseOrder();
  const evaluationRepository = useEvaluation();
  const supplierRepository = useSuppliers();

  const pagination = usePagination();

  const [searchParams, setSearchParams] = useSearchParams();
  const paramsUrlId = (searchParams.has("reqId")) ? searchParams.get("reqId") : null;




  const formatFilter = (_filters: any) => {
    if (_filters.startDateApprover) {
      _filters.startDateApprover = formatDateToServer(
          _filters.startDateApprover
      );
    }
    if (_filters.endDateApprover) {
      _filters.endDateApprover = formatDateToServer(_filters.endDateApprover);
    }
    if (_filters.supplierIdCnpj) {
      for (const objeto of suppliers) {
        if (
            removeMaskCnpj(objeto.cnpj) ===
            removeMaskCnpj(_filters.supplierIdCnpj)
        ) {
          _filters.supplierIdCnpj = objeto.value;
        }
      }
    }
    if(paramsUrlId !== null) {
      _filters.idRequest = paramsUrlId;
      setTimeout(() => {
        searchParams.delete("reqId");
        setSearchParams(searchParams);
      }, 5000);
    }
    return _filters;
  };

  const getEvaluation = async () => {
    const currentPage = pagination.data.current ?? 0;
    const _filters = filters.getObjectifiedFilterItems();
    const formatedFilters = formatFilter(_filters);
    const requestParams = {
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: pagination.data.pageSize ?? 1,
      pendingEvaluation: isActiveApproval,
      ...formatedFilters,
    };


    const responseEvaluation = await evaluationRepository.findEvaluation(
        requestParams
    );
    if (!responseEvaluation) return;

    if (responseEvaluation.content.length === 0)
      message.warn("Nenhuma avaliação foi encontrada");

    pagination.changeTotalPages(responseEvaluation?.totalPages);


    const ordersMapped = responseEvaluation.content.map((order: any) => ({
      key: crypto.randomUUID(),
      ...order
    }))
    setOrders(ordersMapped);
  };

  const getSuppliers = async () => {
    const responseSupplier = await supplierRepository.findSupplierByFilter({
      page: 0,
      size: 10000,
    });
    if (!responseSupplier) return;
    const mappedSupplier = responseSupplier.content.map((item: any) => ({
      value: item.id,
      cnpj: item.cnpj,
    }));

    if (!responseSupplier) return;
    setSuppliers(mappedSupplier);
  };

  const onClickOpenFilterForm = () => {
    setIsVisibleFilterForm(true);
  };

  const getSupplierStatus = (status: any) => {
    switch (status) {
      case "APPROVED":
        return (
            <Label className="label" color={labelBackgroundColor["APPROVED"]} textColor={labelTextColor["APPROVED"]}>
              Homologado
            </Label>
        );
        break;

      case "NOT_APPROVED":
        return (
            <Label className="label" color={labelBackgroundColor["NOT_APPROVED"]} textColor={labelTextColor["NOT_APPROVED"]}>
              Bloqueado
            </Label>
        );
        break;

      default:
        return (
            <Label className="label" color={labelBackgroundColor["IN_ANALYSIS"]} textColor={labelTextColor["IN_ANALYSIS"]}>
              {supplierStatus[status]}
            </Label>
        );
        break;
    }
  };

  useEffect(() => {
    getSuppliers();
    getEvaluation();
  }, [pagination.data.current, pagination.data.pageSize, filters.items, isActiveApproval, refresh]);

  const resetPagination = () => {
    pagination.onChange(1, pagination.data.pageSize ?? 10);
  };

  const getOrderNames = (order: any) => {
    const setNames = new Set();
    order.projectRequest.projects.forEach((project: any) => {
        setNames.add(`${project.idERP} \n ${project.projectName} - ${project.clientName}`);
    });
    return setNames as Set<string>;
  };
  const getOrderProductsNames = (order: any) => {
    const setNames = new Set();
    order.projectRequest.products.forEach((product: any) => {
        setNames.add(product.description);
    });
    return setNames as Set<string>;
  };

  const onFinish = async (order: any) => {
    if (order?.projectRequest?.id !== orderEvaluated?.projectRequest?.id){
      message.error(`Preencha os campos referentes a avaliação da requisição #${order.projectRequest.id}`)
      return ;
    }
    if (
        orderEvaluated.justification === null ||
        orderEvaluated.disableRating && !(orderEvaluated?.justification.length > 4) ||
        orderEvaluated.rating && !(orderEvaluated.justification.length > 4)
    ){
      message.error(`Preencha a justificativa da avaliação da requisição #${order.projectRequest.id}`)
      return ;
    }

    if(orderEvaluated.rating === null && !orderEvaluated.disableRating) {
      message.error(`Selecione a quantidade de estrelas ou escolha a opção "Não consigo avaliar".`)

      return ;
    }

    const evaluation = {
      projectRequestId: orderEvaluated.projectRequest.id,
      justification: orderEvaluated.justification,
      cantEvaluate: orderEvaluated.disableRating,
      score: orderEvaluated.rating
    }

    const request = await evaluationRepository.createEvaluation(evaluation);

    if (request) message.success(`Requisição #${evaluation?.projectRequestId} avaliada com sucesso!`);
    setRefresh(!refresh);
  }

  return (
      <>
        <StyledRow>
          <ButtonBox >
            <YellowButton  onClick={() =>{ setIsActiveApproval(!isActiveApproval) }}>{isActiveApproval ? 'Exibir tudo' : 'Filtrar pendências'}</YellowButton>
          </ButtonBox>
          <ButtonBox>
            <Button onClick={onClickOpenFilterForm}>Filtros</Button>
          </ButtonBox>
        </StyledRow>
        {!orders?.length && !evaluationRepository.loading && (
            <Card className="mt-1">
              <Empty description="Não há avaliações no momento" />
            </Card>
        )}
        <Section
            style={{
              display: "flex",
              flexDirection: "column",
            }}
        >
          <Spin tip="Carregando" spinning={evaluationRepository.loading}>
            {orders?.map((order: any) => {
              const titleContent = order.projectRequest.typeRequest === "COMPOSED" ? (
                  [{
                    "title": "Rateios e contratos",
                    "color": theme.colors.APPROVAL_YELLOW
                  }]
              ) : (
                  [{
                    "title": "Compra e contratação",
                    "color": theme.colors.APPROVAL_BLUE
                  }]
              );

              return (
                  <Box>
                    <ContentTitle backgroundColor={titleContent[0].color}>
                      <div>{titleContent[0].title}</div>
                      <p>#{order?.projectRequest?.id}</p>
                    </ContentTitle>
                    <ContentBox key={order?.id}>
                      <Content>
                        <Info>
                          <div style={{ paddingBottom: "10px" }}>
                            <div className="tagContents">
                              <div className="wrapper">
                                <Label color={purchaseOrderStatusColor[order?.projectRequest.statusRequest]} textColor={purchaseOrderStatusTextColor[order?.projectRequest.statusRequest]}>
                                  {purchaseOrderStatus[order?.projectRequest.statusRequest]}
                                </Label>
                                <Label color={labelBackgroundColor["DEFAULT"]} textColor={labelTextColor["DEFAULT"]}>
                                  {order?.projectRequest?.approvalHierarchyTiming === "PLANEJADA" ?
                                      "Planejada"
                                      :
                                      "Nâo planejada"
                                  }
                                </Label>
                                {order.projectRequest.typeRequest === "COMPOSED" ? (
                                    <Label color={labelBackgroundColor["COMPOSED"]} textColor={labelTextColor["COMPOSED"]}>Composta</Label>

                                ) : (
                                    <Label color={labelBackgroundColor["DEFAULT"]} textColor={labelTextColor["DEFAULT"]}>Simples</Label>
                                )}
                                <Label color={labelBackgroundColor["DEFAULT"]} textColor={labelTextColor["DEFAULT"]}>Com projeto</Label>
                                {!order.projectRequest.id ? (
                                    <Label color={labelBackgroundColor["DEFAULT"]} textColor={labelTextColor["DEFAULT"]}>Verba TV1</Label>
                                ) : (
                                    ""
                                )}
                              </div>
                            </div>
                            <Popover setNames={getOrderNames(order)} title="Projetos" breakline={true} fontColor={purchaseOrderStatusTitleColor[order?.projectRequest.statusRequest]} />
                          </div>

                          <div>
                            <br />
                          </div>
                        </Info>
                        <AdditionalInfo>
                          <InfoOrder>
                            <span className="productTitle">
                              <Popover setNames={getOrderProductsNames(order)} title="Produtos" showmore={true} />
                            </span>
                            <div className="supplierContent">
                              <span><strong>Prestador:</strong> {order?.projectRequest.supplier.fantasyName}</span>
                              <div className="wrapper">
                                {getSupplierStatus(order?.projectRequest.supplier.status)}
                                <Label className="label" color={labelBackgroundColor["DEFAULT"]} textColor={labelTextColor["DEFAULT"]}>
                                  {order?.projectRequest.supplier.type === "SUPPLIER"
                                      ? "Fornecedor"
                                      : "Freelancer"}
                                </Label>
                              </div>
                            </div>
                            <div>
                        <span>
                          <strong>Requisitante:</strong> {order?.projectRequest.requester}
                        </span>
                            </div>
                            <div>
                        <span>
                          <strong>Centro de custos: </strong>
                          {
                            order?.costCenter.name
                          }
                        </span>
                            </div>
                          </InfoOrder>
                        </AdditionalInfo>
                        <Divider />
                        <UserApproval>
                          <Button
                              size="small"
                              onClick={() => onFinish(order)}
                              disabled={!!order.date}
                              loading={evaluationRepository.loading}
                          >
                            Avaliar
                          </Button>
                        </UserApproval>
                      </Content>
                      <EvaluationForm
                          order={order}
                          setOrderEvaluated={setOrderEvaluated}
                      />
                    </ContentBox>
                  </Box>
              );
            })}
          </Spin>
          <FlexEndContainer className="mt-1">
            <Pagination onChange={pagination.onChange} {...pagination.data} />
          </FlexEndContainer>
        </Section>
        <FiltersForm
            resetPagination={resetPagination}
            filters={filters}
            isVisibleDrawer={isVisibleFilterForm}
            setIsVisibleDrawer={setIsVisibleFilterForm}
        />
      </>
  );
};

export default OrderEvaluation;
