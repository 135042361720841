import React, { useEffect, useMemo, useState } from "react";
import type { ColumnsType, TableProps } from "antd/lib/table";
import {
  Collapse,
  Descriptions,
  Divider,
  Table,
  Tag,
  Typography,
  Modal,
} from "antd";
import { useParams } from "react-router";
import {
  TProjectItemList,
  TProjectItemParams,
  TProjectRevision,
  useProjectItems,
} from "repositories";
import { formatCurrency, formatStringToNumber } from "utils/helpers";
import { usePagination } from "hooks";
import { tableLocale } from "utils/lang";
import { DetailGrid, Row, Col, Section } from "components";
import moment from "moment";
import {SorterResult} from "antd/lib/table/interface";

const { Text } = Typography;
const { Panel } = Collapse;

const billingTypes: Record<string, string> = {
  0: "TV1",
  1: "Cliente",
  2: "Compensação de terceiros",
  3: "Compensação diversas",
};

const currentTypes: Record<number, string> = {
  0: "Dolar",
  1: "Euro",
  2: "Real",
};

type TProjectItemData = TProjectItemList & {
  key: string;
};

type TProps = {
  version: TProjectRevision;
};

const VersionList = ({ version }: TProps) => {
  const [items, setItems] = useState<TProjectItemData[]>([]);
  const [requestModal, setRequestModal] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sort, setSort] = useState("sequence,ASC");
  const [isLoading, setIsLoading] = useState(false);

  const repository = useProjectItems();
  const { id } = useParams<{ id: string }>();
  const pagination = usePagination();

  const openRequests = (requests: any) => {
    //console.log(requests);
    setRequestModal(requests);
    setIsModalOpen(true);
  };

  const columns: ColumnsType<TProjectItemData> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Id da linha TOTVS",
      dataIndex: "lineIdErp",
      align: "center",
      key: "lineIdErp",
    },
    {
      title: "Tipo do Faturamento",
      dataIndex: "billingType",
      key: "billingType",
      align: "center",
      width: 100,
    },
    {
      title: "Execução",
      dataIndex: "executionType",
      key: "executionType",
    },
    {
      title: "Produto",
      dataIndex: "productDescription",
      key: "productDescription",
    },
    {
      title: "Categoria",
      dataIndex: "categories.description",
      key: "categories",
      render: (_, record) => {
        const description =
          record.categories &&
          record.categories[0] &&
          record.categories[0].description;

        if (description) {
          return <Text>{description}</Text>;
        } else {
          return <Text type="danger">Descrição não encontrada</Text>;
        }
      },
    },
    {
      title: "Quant.",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Diárias",
      dataIndex: "daily",
      key: "daily",
    },
    {
      title: "Custo unitário",
      dataIndex: "unitCost",
      key: "unitCost",
      align: "left",
      width: 150,
    },
    {
      title: "Custo total",
      dataIndex: "totalCost",
      key: "totalCost",
      align: "left",
      width: 150,
    },
    {
      title: "Já tem requisições?",
      dataIndex: "withoutRequest",
      align: "center",
      render: (_: any, record: any) => {
        return record.projectRequestItems.length > 0 ? (
          <Tag
            color="green"
            style={{ cursor: "pointer" }}
            onClick={() => openRequests(record.projectRequestItems.filter((item: any) => item.status !== "CANCELED"))}
          >
            Sim
          </Tag>
        ) : (
          <Tag color="default">Não</Tag>
        );
      },
    },
    {
      title: "Requisições",
      dataIndex: "valueApprovedRequisitions",
      width: 150,
    },
    {
      title: "Saldo da linha",
      dataIndex: "balanceLine",
      width: 150,
    },
    {
      title: "Status da linha",
      dataIndex: "status",
      key: "status",
      sorter: true,
      align: "center",
      render: (_: any, record: any) => {
        if (record.status) return <Tag color="green">Ativo</Tag>;
  
        return <Tag color="red">Inativo</Tag>;
      },
    },
  ];

  const requestsColumns: any = [
    // {
    //   title: "Id Requisição TOTVS",
    //   dataIndex: "buId",
    // },
    {
      title: "Emissão",
      dataIndex: "issueDate",
      render: (_: any, record: any) => {
        return record.issueDate
          ? moment(record.issueDate).format("DD/MM/YYYY")
          : "";
      },
    },
    {
      title: "Vencimento",
      dataIndex: "maturity",
      render: (_: any, record: any) => {
        return record.maturity
          ? moment(record.maturity).format("DD/MM/YYYY")
          : "";
      },
    },
    {
      title: "Produto",
      dataIndex: "productName",
    },
    {
      title: "Prestador",
      dataIndex: "typeProvider",
      render: (_: any, record: any) => {
        return record.typeProvider === "SUPPLIER" ? "Fornecedor" : "Freelancer";
      },
    },
    {
      title: "Tipo",
      dataIndex: "typeRequest",
      render: (_: any, record: any) => {
        if (record.typeRequest === "SIMPLE") return "Simples";
        return "Composta";
      },
    },
    {
      title: "Categoria",
      dataIndex: "category",
    },
    {
      title: "Alocação",
      dataIndex: "allocation",
    },
    {
      title: "Local",
      dataIndex: "buName",
    },
    {
      title: "Cliente",
      dataIndex: "client",
    },
    {
      title: "Timing",
      dataIndex: "timing",
      render: (_: any, record: any) => {
        if (record.timing === "PLANEJADA")
          return <Tag color="green">Planejada</Tag>;

        return <Tag color="red">Não planejada</Tag>;
      },
    },
    {
      title: "Fatura",
      dataIndex: "paymentType",
    },
    {
      title: "Valor da requisição",
      dataIndex: "totalCost",
      render: (_: any, record: any) => {
        return formatCurrency(record?.totalCost);
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_: any, record: any) => {
        switch (record.status) {
          case "NEW":
            return <Tag color="cyan">Novo</Tag>;
          case "IN_QUOTATION":
            return <Tag color="yellow">Em cotação</Tag>;
          case "APPROVED":
            return <Tag color="green">Aprovado</Tag>;
          case "REFUSED":
            return <Tag color="orange">Reprovada</Tag>;
          case "CANCELED":
            return <Tag color="red">Cancelada</Tag>;
          case "ON_APPROVAL":
            return <Tag color="yellow">Em aprovação</Tag>;
          case "PAID_OUT":
            return <Tag color="green">Pago</Tag>;
          default:
            return <Tag>{record.status}</Tag>;
        }
      },
    },
  ];

  const getItems = async () => {
    setIsLoading(true);
    // console.log({pagination})
    const currentPage = pagination.data.current ?? 0;
    const params: TProjectItemParams = {
      projectId: Number(id),
      projectRevisionId: version.id,
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: 10000,
      sort,
    };
    const response = await repository.findProjectItemsByFilter(params);
    if (!response) {
      setIsLoading(false);
      return;
    }

    const [projectItem] = response;

    const formattedItems = projectItem?.items?.map((item) => ({
      ...item,
      key: String(item.id),
      checked: false,
      disabled: false,
      salesMargin: formatCurrency(item.salesMargin, false),
      unitSale: formatCurrency(item.unitSale, false),
      rate: formatCurrency(item.rate, false),
      unitCost: `R$ ${formatCurrency(item.unitCost, false)}`,
      totalCost: `R$ ${formatCurrency(item.totalCost, false)}`,
      saving: item.saving,
      valueApprovedRequisitions: `R$ ${formatCurrency(
        item.valueApprovedRequisitions ? item.valueApprovedRequisitions : 0,
        false
      )}`,
      balanceLine: `R$ ${formatCurrency(
          item.valueApprovedRequisitions ? Number(item.balanceLine) : item.totalCost,
        false
      )}`,
      billingType: billingTypes[item.billingType],
      executionType: item.executionType === "I" ? "Interna" : "Externa",
      foreignCurrency: {
        currencyType: currentTypes[item.foreignCurrency?.currencyType],
        totalCost: formatCurrency(item.foreignCurrency?.totalCost, false),
        totalSale: formatCurrency(item.foreignCurrency?.totalSale, false),
        fee: formatCurrency(item.foreignCurrency?.fee, false),
      },
    }));
    setItems(formattedItems);
    setIsLoading(false);
  };

  const handleChange: TableProps<any>["onChange"] = (
      paginationConfig,
      sorter
  ) => {
    pagination.onChangeTable(paginationConfig);
    const { field, order } = sorter as SorterResult<any>;
    if (!field) return;

    const dir = order === "ascend" ? "ASC" : "DESC";
    setSort(`${field},${dir}`);
  };

  useEffect(() => {
    if (version.id && pagination.data.current) getItems();
  }, [pagination.data.current, pagination.data.pageSize, version,sort]);

  const formattedIndicators = useMemo(() => {
    if (!version.indicators) return version;
    const { cost, currencyExchange, sale, news } = version.indicators;
    return {
      ...version,
      indicators: {
        cost: {
          tv1: formatCurrency(cost?.tv1, false),
          tax: formatCurrency(cost?.tax, false),
          reimbursement: formatCurrency(cost?.reimbursement, false),
        },
        currencyExchange: {
          dollar: formatCurrency(currencyExchange?.dollar, false),
          euro: formatCurrency(currencyExchange?.euro, false),
          real: formatCurrency(currencyExchange?.real, false),
        },
        sale: {
          compensation: formatCurrency(sale?.compensation, false),
          interest: formatCurrency(sale?.interest, false),
          tax: formatCurrency(sale?.tax, false),
          rate: formatCurrency(sale?.rate, false),
          reimbursement: formatCurrency(sale?.reimbursement, false),
          shipping: formatCurrency(sale?.shipping, false),
          taxCompensation: formatCurrency(sale?.taxCompensation, false),
          tv1: formatCurrency(sale?.tv1, false),
        },
        news: {
          currentTv1ExternalCost: formatCurrency(
            news?.currentTv1ExternalCost ? news?.currentTv1ExternalCost : 0,
            false
          ),
          budgetedTv1ExternalCost: formatCurrency(
            news?.budgetedTv1ExternalCost ? news?.budgetedTv1ExternalCost : 0,
            false
          ),
          soldTv1Tax: formatCurrency(
            news?.soldTv1Tax ? news?.soldTv1Tax : 0,
            false
          ),
          budgetedTv1Tax: formatCurrency(
            news?.budgetedTv1Tax ? news?.budgetedTv1Tax : 0,
            false
          ),
          currentTv1LiquidSale: formatCurrency(
            news?.currentTv1LiquidSale ? news?.currentTv1LiquidSale : 0,
            false
          ),
          budgetedTv1LiquidSale: formatCurrency(
            news?.budgetedTv1LiquidSale ? news?.budgetedTv1LiquidSale : 0,
            false
          ),
          currentGrossMargin: formatCurrency(
            news?.currentGrossMargin ? news?.currentGrossMargin : 0,
            false
          ),
          grossMarginSold: formatCurrency(
            news?.grossMarginSold ? news?.grossMarginSold : 0,
            false
          ),
          percCurrentGrossMargin: news?.percCurrentGrossMargin
            ? news?.percCurrentGrossMargin
            : 0,
          percGrossMarginSold: news?.percGrossMarginSold
            ? news?.percGrossMarginSold
            : 0,
        },
      },
    };
  }, [version]);

  const summary = useMemo(() => {
    const formatToNumber = (value: string) => formatStringToNumber(value);
    const summaryData = items?.reduce(
      (acc, curr) => ({
        totalAmount: acc.totalAmount + curr.amount,
        totalDaily: acc.totalDaily + curr.daily,
        totalUnitCost: acc.totalUnitCost + formatToNumber(curr.unitCost),
        totalCost: acc.totalCost + formatToNumber(curr.totalCost),
        totalUnitSale: acc.totalUnitSale + formatToNumber(curr.unitSale),
        totalSaving: acc.totalSaving + curr.saving,
      }),
      {
        totalAmount: 0,
        totalDaily: 0,
        totalUnitCost: 0,
        totalCost: 0,
        totalUnitSale: 0,
        totalSaving: 0,
      }
    );

    const formattedSaving = formatCurrency(
      summaryData?.totalSaving || 0,
      false
    );

    const totalSavingText =
      summaryData?.totalSaving < 0 ? (
        <Text type="danger">{formattedSaving}</Text>
      ) : (
        <Text>{formattedSaving}</Text>
      );
    return (
      <Table.Summary.Row>
        <Table.Summary.Cell colSpan={7} index={1} align="right">
          Total
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2}>
          {summaryData?.totalAmount || 0}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2}>
          {summaryData?.totalDaily || 0}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2} align="right">
          {formatCurrency(summaryData?.totalUnitCost || 0, false)}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2} align="right">
          {formatCurrency(summaryData?.totalCost || 0, false)}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2} align="right">
          {formatCurrency(summaryData?.totalUnitSale || 0, false)}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={7} colSpan={2} />
        <Table.Summary.Cell index={2} align="right">
          {totalSavingText}
        </Table.Summary.Cell>
      </Table.Summary.Row>
    );
  }, [items]);

  return (
    <>
      <Divider plain orientation="left">
        Indicadores
      </Divider>
      <Section>
        <Row>
          <Col xl={6} xxl={4}>
            <DetailGrid>
              <Descriptions.Item label="Valor de Venda TV1">
                <span
                  style={{
                    border: "2px solid black",
                    width: "max-content",
                    padding: "0 5px",
                    minWidth: "100px",
                  }}
                >
                  {" "}
                  R$ {formattedIndicators.indicators?.sale?.tv1}
                </span>
              </Descriptions.Item>
            </DetailGrid>
          </Col>
          <Col xl={6} xxl={4}>
            <DetailGrid>
              <Descriptions.Item label="Imposto na venda TV1">
                <span
                  style={{
                    border: "2px solid black",
                    width: "max-content",
                    padding: "0 5px",
                    minWidth: "100px",
                  }}
                >
                  R$ {formattedIndicators.indicators?.news?.budgetedTv1Tax}
                </span>
              </Descriptions.Item>
            </DetailGrid>
          </Col>
          <Col xl={6} xxl={4}>
            <DetailGrid>
              <Descriptions.Item label="Receita líquida orçada TV1">
                <span
                  style={{
                    border: "2px solid black",
                    width: "max-content",
                    padding: "0 5px",
                    minWidth: "100px",
                  }}
                >
                  R${" "}
                  {formattedIndicators.indicators?.news?.budgetedTv1LiquidSale}
                </span>
              </Descriptions.Item>
            </DetailGrid>
          </Col>
          <Col xl={6} xxl={4}>
            <DetailGrid>
              <Descriptions.Item label="Custos externos orçados TV1">
                <span
                  style={{
                    border: "2px solid black",
                    width: "max-content",
                    padding: "0 5px",
                    minWidth: "100px",
                  }}
                >
                  R${" "}
                  {
                    formattedIndicators.indicators?.news
                      ?.budgetedTv1ExternalCost
                  }
                </span>
              </Descriptions.Item>
            </DetailGrid>
          </Col>
          <Col xl={6} xxl={4}>
            <DetailGrid>
              <Descriptions.Item label="Margem Bruta vendida R$">
                <span
                  style={{
                    border: "2px solid black",
                    width: "max-content",
                    padding: "0 5px",
                    minWidth: "100px",
                  }}
                >
                  R$ {formattedIndicators.indicators?.news?.grossMarginSold}
                </span>
              </Descriptions.Item>
            </DetailGrid>
          </Col>
          <Col xl={6} xxl={4}>
            <DetailGrid>
              <Descriptions.Item label="Margem Bruta vendida %">
                <span
                  style={{
                    border: "2px solid black",
                    width: "max-content",
                    padding: "0 5px",
                    minWidth: "100px",
                  }}
                >
                  {formattedIndicators.indicators?.news?.percGrossMarginSold}%
                </span>
              </Descriptions.Item>
            </DetailGrid>
          </Col>
          <Col xl={6} xxl={4}>
            <DetailGrid>
              <Descriptions.Item label="Imposto projetado (corrente) TV1">
                <span
                  style={{
                    border: "2px solid black",
                    width: "max-content",
                    padding: "0 5px",
                    minWidth: "100px",
                  }}
                >
                  R$ {formattedIndicators.indicators?.news?.soldTv1Tax}
                </span>
              </Descriptions.Item>
            </DetailGrid>
          </Col>
          <Col xl={6} xxl={4}>
            <DetailGrid>
              <Descriptions.Item label="Receita líquida corrente TV1">
                <span
                  style={{
                    border: "2px solid black",
                    width: "max-content",
                    padding: "0 5px",
                    minWidth: "100px",
                  }}
                >
                  R${" "}
                  {formattedIndicators.indicators?.news?.currentTv1LiquidSale}
                </span>
              </Descriptions.Item>
            </DetailGrid>
          </Col>
          <Col xl={6} xxl={4}>
            <DetailGrid>
              <Descriptions.Item label="Custos externos correntes TV1">
                <span
                  style={{
                    border: "2px solid black",
                    width: "max-content",
                    padding: "0 5px",
                    minWidth: "100px",
                  }}
                >
                  R${" "}
                  {formattedIndicators.indicators?.news?.currentTv1ExternalCost}
                </span>
              </Descriptions.Item>
            </DetailGrid>
          </Col>
          <Col xl={6} xxl={4}>
            <DetailGrid>
              <Descriptions.Item label="Margem Bruta corrente R$">
                <span
                  style={{
                    border: "2px solid black",
                    width: "max-content",
                    padding: "0 5px",
                    minWidth: "100px",
                  }}
                >
                  R$ {formattedIndicators.indicators?.news?.currentGrossMargin}
                </span>
              </Descriptions.Item>
            </DetailGrid>
          </Col>
          <Col xl={6} xxl={4}>
            <DetailGrid>
              <Descriptions.Item label="Margem Bruta corrente %">
                <span
                  style={{
                    border: "2px solid black",
                    width: "max-content",
                    padding: "0 5px",
                    minWidth: "100px",
                  }}
                >
                  {formattedIndicators.indicators?.news?.percCurrentGrossMargin}
                  %
                </span>
              </Descriptions.Item>
            </DetailGrid>
          </Col>
        </Row>
      </Section>
      {/* <Collapse
        accordion
        ghost
      >
        <Panel header='Custos' key='1'>
          <DetailGrid>
            <Descriptions.Item label='TV1'>
              {formattedIndicators.indicators?.cost?.tv1}
            </Descriptions.Item>
            <Descriptions.Item label='Taxa'>
              {formattedIndicators.indicators?.cost?.tax}
            </Descriptions.Item>
            <Descriptions.Item label='Reeimbolso'>
              {formattedIndicators.indicators?.cost?.reimbursement}
            </Descriptions.Item>
          </DetailGrid>
        </Panel>
        <Panel header='Câmbio Monetário' key='2'>
          <DetailGrid>
            <Descriptions.Item label='Dolar'>
              {formattedIndicators.indicators?.currencyExchange?.dollar}
            </Descriptions.Item>
            <Descriptions.Item label='Euro'>
              {formattedIndicators.indicators?.currencyExchange?.euro}
            </Descriptions.Item>
            <Descriptions.Item label='Real'>
              {formattedIndicators.indicators?.currencyExchange?.real}
            </Descriptions.Item>
          </DetailGrid>
        </Panel>
        <Panel header='Vendas' key='3'>
          <DetailGrid>
            <Descriptions.Item label='Compensação'>
              {formattedIndicators.indicators?.sale?.compensation}
            </Descriptions.Item>
            <Descriptions.Item label='Juros'>
              {formattedIndicators.indicators?.sale?.interest}
            </Descriptions.Item>
            <Descriptions.Item label='Taxas'>
              {formattedIndicators.indicators?.sale?.rate}
            </Descriptions.Item>
            <Descriptions.Item label='Reembolso'>
              {formattedIndicators.indicators?.sale?.reimbursement}
            </Descriptions.Item>
            <Descriptions.Item label='Frete'>
              {formattedIndicators.indicators?.sale?.shipping}
            </Descriptions.Item>
            <Descriptions.Item label='Imposto'>
              {formattedIndicators.indicators?.sale?.tax}
            </Descriptions.Item>
            <Descriptions.Item label='Compensação Fiscal'>
              {formattedIndicators.indicators?.sale?.taxCompensation}
            </Descriptions.Item>
            <Descriptions.Item label='TV1'>
              {formattedIndicators.indicators?.sale?.tv1}
            </Descriptions.Item>
          </DetailGrid>
        </Panel>
      </Collapse> */}
      <Divider plain orientation="left">
        Linhas
      </Divider>
      <Table
        size="middle"
        columns={columns}
        dataSource={items}
        pagination={false}
        loading={isLoading}
      />
      <Modal
        title="Requisições"
        className="horizontal-scrollable-modal"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        width={1300}
      >
        <Table
          columns={requestsColumns}
          dataSource={requestModal}
          pagination={false}
          style={{ overflowX: "auto" }}
        />
      </Modal>
    </>
  );
};

export default VersionList;
