import React, { useEffect, useState } from 'react'
import {Button, Form, message, Modal, Pagination} from 'antd'
import Popover from "../../../Tracker/Components/Popover";
import {TPurchaseOrder, useSuppliers} from 'repositories'

import {
    Box,
    ContentBox,
    ContentRow,
    Content,
    StarsContainer,
    Star,
    ScoreText,
    DateText,
    Card,
    Header,
    Justification,
    Text,
    BarBackground,
    BarContainer,
    ContainerRating,
    RatingText,
    BarFill, ContentRowFilters, ButtonBox, OpaqueText, BoldText, ContentLeft
} from "./styles";

import {useFilters, usePagination} from "../../../../hooks";
import {FlexEndContainer, SelectField} from "../../../../components";
import {formatDateToServer, formatDateToView} from "../../../../utils/helpers";
import FiltersForm from "./Components/FiltersForm";


const formatFilter = (_filters: any) => {
    if (_filters.startDate) {
        _filters.startDate = formatDateToServer(_filters.startDate);
    }
    if (_filters.endDate) {
        _filters.endDate = formatDateToServer(_filters.endDate);
    }
    return _filters;
};

export const classificationOptions = [
    {
        label: "Selecione",
        value: "",
    },
    {
        label: "Maior classificação",
        value: "DESC",
    },
    {
        label: "Menor classificação",
        value: "ASC",
    },
]
export const sortOptions = [
    {
        label: "Selecione",
        value: "",
    },
    {
        label: "Mais recentes",
        value: "DESC",
    },
    {
        label: "Mais antigas",
        value: "ASC",
    },
]


type TProps = {
    isModalVisible: boolean
    setIsModalVisible: React.Dispatch<boolean>,
    supplierId: any
}
const EvaluationModal = (props: TProps) => {

    const {
        isModalVisible,
        setIsModalVisible,
        supplierId,
    } = props

    const [evaluations, setEvaluations] = useState<any>({});
    const [evaluationsWhihoutFilters, setEvaluationsWhihoutFilters] = useState<any>({});
    const [isVisibleFilterForm, setIsVisibleFilterForm] = useState(false);
    const [sort, setSort] = useState("");
    const [classification, setClassification] = useState("");

    const [form] = Form.useForm()

    const filters = useFilters();
    const repositorySupplier = useSuppliers()
    const pagination = usePagination();

    const getEvaluation = async () => {
        const currentPage = pagination.data.current ?? 0;
        const _filters = filters.getObjectifiedFilterItems();
        const formatedFilters = formatFilter(_filters);
        let sortFilter, classificationFilter

        const queryParams = new URLSearchParams({
            page: currentPage === 0 ? String(currentPage) : String(currentPage - 1),
            size: String(3),
            ...formatedFilters,
        });
        if(sort.length > 0) {
            sortFilter = `date,${sort}`
            queryParams.append("sort", sortFilter);
        }

        if(classification.length > 0) {
            classificationFilter = `score,${classification}`
            queryParams.append("sort","cantEvaluate,ASC")
            queryParams.append("sort", classificationFilter);
        }

        const response = await repositorySupplier.findSupplierEvaluation(supplierId, queryParams)
        if (!response) return


        if(response?.evaluations?.totalElements === 0 && isModalVisible)  message.warn("Não foi encontrada nenhuma avaliação");

        pagination.onChange(currentPage,3)
        pagination.changeTotalPages(response?.evaluations?.totalPages);
        setEvaluations({...response})
        if (Object.keys(_filters).length === 0){
            setEvaluationsWhihoutFilters({...response})
        }
    }

    useEffect(() => {
      getEvaluation()
    }, [isModalVisible,pagination.data.current, pagination.data.pageSize, filters.items, sort, classification])

    const handleClose = () => {
        form.resetFields()
        filters.clearAndAdd([])
        setEvaluations({})
        setEvaluationsWhihoutFilters({})
        setClassification("")
        setIsModalVisible(false);
    }

    useEffect(() => {
        if (isModalVisible) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isModalVisible]);

    const ratings = [
        evaluationsWhihoutFilters.countFiveScore,
        evaluationsWhihoutFilters.countFourScore,
        evaluationsWhihoutFilters.countThreeScore,
        evaluationsWhihoutFilters.countTwoScore,
        evaluationsWhihoutFilters.countOneScore,
    ];

    const totalRatings = ratings.reduce((acc, val) => acc + val, 0);

    const onClickOpenFilterForm = () => {
        setIsVisibleFilterForm(true);
    };

    const resetPagination = () => {
        pagination.onChange(1, pagination.data.pageSize ?? 3);
    };

    const onChangeSort = async () => {
        const sort = form.getFieldValue("sort")
        setSort(sort)
    }
    const onChangeClassification = async () => {
        const classification = form.getFieldValue("classification")
        setClassification(classification)
    }

    const initialValues = {
        sort: "",
        classification: "",
    }

    const getOrderNames = (evaluation: any) => {

        const setNames = new Set();

        evaluation?.projectRequest?.projects.forEach((item: any) => {
                setNames.add(`${String(item?.idERP)} - ${String(item?.projectName)} - ${String(item?.clientName)}`);
        });
        return setNames as Set<string>;
    };
    const getOrderProducts = (evaluation: any) => {
        const setNames = new Set();

        evaluation?.projectRequest?.products.forEach((item: any) => {
            setNames.add(`${String(item?.description)}`);
        });
        return setNames as Set<string>;
    };

    const getJustificationById = (id: number): string | undefined => {
        if (!evaluationsWhihoutFilters || !evaluationsWhihoutFilters.evaluations || !evaluationsWhihoutFilters.evaluations.content) {
            console.error("Objeto inválido ou estrutura incorreta");
            return undefined;
        }

        const evaluation = evaluationsWhihoutFilters.evaluations.content.find((item: any) => item.id === id);
        return evaluation?.justification;
    };

    return (
        <Modal
            title="Resumo das avaliações"
            visible={isModalVisible}
            okText=""
            cancelText="Fechar"
            confirmLoading={repositorySupplier.loading}
            onCancel={handleClose}
            width='57%'
            footer={null}
            bodyStyle={{ height: '70%' }}
        >
            <Form
                form={form}
                initialValues={initialValues}
            >
                <ContentRowFilters>
                    <ButtonBox type="select" >
                        <SelectField
                            name='sort'
                            label='Ordenar'
                            options={sortOptions}
                            onChange={onChangeSort}
                        />
                    </ButtonBox>
                    <ButtonBox type="select">
                        <SelectField
                            name='classification'
                            label='Classificar'
                            options={classificationOptions}
                            onChange={onChangeClassification}

                        />
                    </ButtonBox>
                    <ButtonBox>
                        <Button onClick={onClickOpenFilterForm}>Filtros</Button>
                    </ButtonBox>
                </ContentRowFilters>
                <Box>
                    <ContentBox>
                        <ContentLeft>
                            <StarsContainer>
                                <ScoreText>{evaluationsWhihoutFilters?.averageScore?.toFixed(2)}</ScoreText>
                                {[...Array(5)].map((_, index) => (
                                    <Star key={index} active={index < evaluationsWhihoutFilters?.averageScore} size={"25px"} />
                                ))}
                                <Text>{evaluationsWhihoutFilters?.evaluations?.totalElements} avaliações</Text>
                            </StarsContainer>
                            <ContainerRating>
                                {ratings.map((count, index) => {
                                    const percentage = totalRatings ? (count / totalRatings) * 100 : 0;
                                    return (
                                        <BarContainer key={index}>
                                            <BarBackground>
                                                <BarFill percentage={percentage} />
                                            </BarBackground>
                                            <RatingText>{5 - index} ☆</RatingText>
                                        </BarContainer>
                                    );
                                })}
                                <OpaqueText>{evaluations?.countCantEvaluate} Não conseguiram avaliar</OpaqueText>
                            </ContainerRating>
                        </ContentLeft>
                        <Content>
                            {evaluations?.evaluations?.content.map((evaluation: any) => {
                                const justification = getJustificationById(evaluation.id)
                                return <>
                                    <Card>
                                        <Header>
                                            <StarsContainer>
                                                {evaluation?.cantEvaluate ? (
                                                    <span style={{ color: "gray", opacity: 0.7, fontSize: "0.875rem" }}>Não consigo avaliar</span>
                                                ) : (
                                                    [...Array(5)].map((_, index) => (
                                                        <Star key={index} active={index < evaluation?.score} size={"12px"} />
                                                    ))
                                                )}
                                            </StarsContainer>
                                            <BoldText>
                                                #{evaluation.projectRequest.id}
                                            </BoldText>

                                            <DateText>{formatDateToView(evaluation.date)}</DateText>
                                        </Header>
                                        <ContentRow>
                                            <text style={{fontSize: "12px", fontWeight: "normal"}}>Avaliador: {evaluation.userName}</text>
                                        </ContentRow>
                                        <ContentRow style={{fontSize: "14px"}}>
                                            <Popover setNames={getOrderNames(evaluation)} title="Projetos avaliados" />
                                        </ContentRow>
                                        <ContentRow style={{fontSize: "14px", fontWeight: "normal"}}>
                                            <Popover setNames={getOrderProducts(evaluation)} title="Produtos avaliados" />
                                        </ContentRow>
                                        <br/>
                                        <Justification
                                            value={justification}
                                        />
                                    </Card>
                                    <br/>
                                </>
                            })}
                        </Content>
                    </ContentBox>
                    <FlexEndContainer className="mt-1">
                        <Pagination
                            onChange={pagination.onChange}
                            total={pagination.data.total}
                            current={pagination.data.current}
                            pageSize={pagination.data.pageSize}
                        />
                    </FlexEndContainer>
                </Box>
                <FiltersForm
                    resetPagination={resetPagination}
                    filters={filters}
                    isVisibleDrawer={isVisibleFilterForm}
                    setIsVisibleDrawer={setIsVisibleFilterForm}
                />
            </Form>
        </Modal>

    )
}

export default EvaluationModal