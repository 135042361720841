import moment, { Moment } from "moment";
import { cnpj as cnpjValidator, cpf as cpfValidator } from "cpf-cnpj-validator";
import { RcFile } from "antd/lib/upload";
import { message } from "antd";
import { acceptedExtensionsFile } from "./constants";

export const fileToBase64 = async (file: Blob) => {
  const base64 = await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const [, data] = String(base64).split(",");

  return data;
};

export const currentDate = () => moment(new Date()).format("DD/MM/YYYY");

export const addDaysToDate = (date: string, amount: number) =>
  moment(date).add(amount, "days").format("DD/MM/YYYY");

export const formatTimeToView = (date: string) => moment(date).format("HH:MM");

export const formatDateToView = (date: string | Moment) => {
  if (date) {
    return moment(date).format("DD/MM/YYYY");
  }
  return "";
};

export const formatDateForRequest = (date: string | Moment) => {
  if (date) {
    return moment(date).format("DD/MM/YY");
  }
  return "";
};

export const formatDate = (date: string | Moment) =>
  moment(date).format("YYYY-MM-DD");

export const formatDateTimeToView = (date: string) =>
  moment(date).format("DD/MM/YYYY HH:MM");

export const convertMomentToDate = (date: Moment) => date.format("YYYY-MM-DD");

export const formatDateToServer = (date: string) => {
  if (!date || date === "undefined") return "";

  if (date.includes("/")) {
    return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
  }

  return moment(date).format("YYYY-MM-DD");
};

export const maskTelephone = (telephone: string) => {
  if (!telephone.length) return "";
  const ddd = telephone.substring(0, 2);
  if (telephone.length === 11) {
    const firstChunk = telephone.substring(2, 7);
    const lastChunk = telephone.substring(7, 11);
    return `(${ddd}) ${firstChunk}-${lastChunk}`;
  }

  const firstChunk = telephone.substring(2, 6);
  const lastChunk = telephone.substring(6, 10);
  return `(${ddd}) ${firstChunk}-${lastChunk}`;
};

export const formatCurrency = (number: string | number, symbol = true) => {
  if (typeof number === "string" && number.includes(",")) return number;

  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  const formattedNumber = formatter.format(Number(number));
  return symbol ? formattedNumber : formattedNumber.replace("R$", "").trim();
};
export const  removeCurrency = (number: any) =>{
  const value = number.replace(/[^\d.,]/g, '');
  const valueWhitPoint = value.replace(',', '.');
  const numberFormated = parseFloat(valueWhitPoint);

  return numberFormated;
}

export const formatToPercent = (number: number) => {
  const percent = `${number} %`;

  return percent;
}

export const formatDecimalNumber = (number: number) => {
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "decimal",
    maximumFractionDigits: 2,
  });

  return formatter.format(number);
};

export const convertNumberToInt = (number: string | number) => {
  if (typeof number === "number") {
    return parseInt(number.toString().replace(",", "."), 10);
  }
  return parseInt(number.replace(",", "."), 10);
};

export const convertStringToNumber = (number: string | number) => {
  if (typeof number === "number") {
    return Number(number.toString().replace(",", "."));
  }
  return Number(number.replaceAll(".", "").replace(",", "."));
};

export const formatStringToNumber = (number: string) =>
  parseFloat(formatCurrencyToServer(number));

export const formatCurrencyToServer = (number: string) => {
  if (typeof number === "number") return number;
  if (!number) return "0";

  if (number.indexOf(",")) {
    return number.replace(/\./g, "").replace(",", ".").replace(" ", "");
  }

  return number.trim();
};

export const removeMaskGuides = (value: string) =>
  value
    .replace(/_/g, "")
    .replace(/-/g, "")
    .replace(/\(/g, "")
    .replace(/\)/g, "")
    .replace(/ /g, "")
    .replace(/[^\d]+/g, "");

export const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const validTextOnly = (token: string) => {
  try {
    return token.replace(/[^a-zA-Z\s]+/,'');
  } catch (e) {
    return null;
  }
};

export const validEmailOnly = (email: string) => {
  try {
    const regex = /^([^@]+)@/;
    const match = email.match(regex);
    return match ? match[1] : '';
  } catch (e) {
    return 'TV1';
  }
};

export const getYesNo = (bool: boolean) => {
  if (bool) return "Sim";

  return "Não";
};

export const validateCnpj = (cnpj: string) => {
  const isCnpjValid = cnpjValidator.isValid(cnpj);
  if (isCnpjValid) return Promise.resolve();

  return Promise.reject(new Error("CNPJ informado está inválido"));
};

export const validateCnpjAndRequired = (cnpj: string) => {
  if (cnpj) {
    const isCnpjValid = cnpjValidator.isValid(cnpj);
    if (isCnpjValid) return Promise.resolve();

    return Promise.reject(new Error("CNPJ informado está inválido"));
  } else {
    return Promise.reject(new Error("Campo 'CNPJ' é obrigatório"));
  }
};

export const removeMaskCnpj = (cnpj: string) => {
  const cnpjPattern = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;

  if (typeof cnpj !== 'number') cnpj = cnpj.trim();


  if (!cnpjPattern.test(cnpj)) {
    return cnpj;
  }

  return cnpj.replace(/\D/g, "");
};

export const validateCpf = (cpf: string) => {
  const isCpfValid = cpfValidator.isValid(cpf);
  if (isCpfValid) return Promise.resolve();

  return Promise.reject(new Error("CPF informado está inválido"));
};

export const downloadBase64File = async (
  fileName: string,
  base64string: string,
  fileFormat = "application/pdf"
) => {
  await new Promise<void>((resolve, reject) => {
    try {
      const linkSource = `data:${fileFormat};base64,${base64string}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const validFile = (file: RcFile) => {
  const { name } = file;
  const isExcededFileSize = file.size >= 10048576;

  if (isExcededFileSize) {
    message.error("O arquivo excedeu o tamanho permitido de 10MB");
    return false;
  }

  const isValidExtension = acceptedExtensionsFile.some((extension) =>
    name.includes(extension)
  );
  if (!isValidExtension) {
    message.error(`${file.name} não está com uma extensão válida.`);
    return false;
  }
};
