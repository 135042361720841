import React, { useCallback, useEffect, useState, useRef, useMemo } from "react";
import {Form, List, Table, Tooltip, Popover as Pop} from "antd";
import { IoIosArrowDown, IoIosArrowBack } from "react-icons/io";
import { Container, Header, Content } from "./styles";
import {formatCurrency, formatDateToView} from "../../../../utils/helpers";

const columnsApportionmentWithInstallment = [
  {
    title: "ID do projeto",
    dataIndex: "projectIdERP",
    fixed: 'left',
  },
  // {
  //   title: "Projeto",
  //   dataIndex: "projectName",
  //   onHeaderCell: () => ({
  //     style: { minWidth: 220 },
  //   }),
  // },
  // {
  //   title: "Linha",
  //   dataIndex: "lineIdErp",
  //   onHeaderCell: () => ({
  //     style: { minWidth: 220 },
  //   }),
  //   render: (_: any, record: any) => (
  //     <Pop content={record?.lineIdErp} title="Linha - Descrição">
  //       {`${record?.lineIdErp?.substring(0, 22)}...`}
  //     </Pop>
  //   ),
  // },
  // {
  //   title: "Percentual Rateio (%)",
  //   dataIndex: "percentage",
  //   render: (_: any, record: any) => {
  //     return `${record.percentage?.toFixed(2)} %`;
  //   },
  // },
]

const ApportionmentInformation = ({ key, data, toggle, resetStatusToggle, record }: any) => {
  const [items, setItems] = useState([]);
  const [columnsInstallment, setColumnsInstallment] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [form] = Form.useForm<any>();
  const isFirstRender = useRef(true);

  const [summaryItems, setSummaryItems] = useState<any>([]);

  const handleOpen = () => {setIsOpen(!isOpen); resetStatusToggle()};

  const startedOpen = () => {
    setIsOpen(true);
    resetStatusToggle();
  }

  // const getApportionmentInformation = async () => {
  //   const columnsInstallment: any = []

  //   const sortedItems = {
  //     ...data?.projectRequest,
  //     items: data?.projectRequest?.items?.map((item: any) => ({
  //       ...item,
  //       apportions: item?.apportions?.sort((a: any, b: any) => {
  //         const dateA: any = new Date(a.maturityApportion);
  //         const dateB: any = new Date(b.maturityApportion);
  //         return dateA - dateB;
  //       })
  //     }))
  //   }

  //   sortedItems.items[0]?.apportions?.forEach((apportion: any, index: any) => {
  //     columnsInstallment.push({
  //       title: `${index + 1}° Parcela \n ${formatDateToView(apportion.maturityApportion)}`,
  //       dataIndex: `${index + 1}installment`,
  //       render: (apportion: any, record: any) => {
  //         return <span>{formatCurrency(record?.apportions[index]?.valueApportion)}</span>
  //       }
  //     });
  //   });

  //   const updatedColumns = [
  //     ...columnsApportionmentWithInstallment,
  //     ...columnsInstallment,
  //     {
  //       title: "Total",
  //       dataIndex: "totalValue",
  //       render: (_: any, record: any) => {
  //         return <span>{formatCurrency(record?.apportions?.reduce((soma: any, valorAtual: any) => soma + valorAtual?.valueApportion, 0))}</span>
  //       }
  //     },
  //   ]

  //   setColumnsInstallment(updatedColumns)

  //   setItems(data?.projectRequest.items)

  // };

  const timelineStatusIconsNColors: Record<
    string,
    { name: string; color: string }
  > = {
    NOT_DEFINED: { name: "", color: "#4e4e4e" },
    ACCEPT: { name: "Aceito", color: "#048d03" },
    RECEIVED: { name: "Recebido", color: "#0A84FF" },
    APPROVED: { name: "Aprovado", color: "#048d03" },
    REFUSED: { name: "Recusado", color: "red" },
    CANCELED: { name: "Cancelado", color: "red" },
    PAID_OUT: { name: "Pago", color: "#048d03" },
    CURRENT: { name: "Em andamento", color: "#ed8306" },
    STEP_IS_NOT_REQUIRED: { name: "NA", color: "#4e4e4e" },
  };

  const getStatusColor = (status: string) => {
    if (!status) return "#ed8306";
    return timelineStatusIconsNColors[status].color;
  };

  const getStatusText = (status: string) => {
    if (!status) return "";
    return timelineStatusIconsNColors[status].name;
  };

  const getlatestStatus = (status: any[]) =>{
    // return status.reduce((acc, item) => acc[item.id - 1] = item, [])
    return status.sort((a, b) => b.id - a.id)
  } 

  const getApportionmentInformation = async () => {

    const columnsInstallment: any = []
    const rowSomatory : any = [];

    const sortedItems = {
      ...record,
      items: record?.items?.map((item: any) => ({
        ...item,
        apportions: item?.apportions?.sort((a: any, b: any) => {
          const dateA: any = new Date(a.dueDate);
          const dateB: any = new Date(b.dueDate);
          return dateA - dateB;
        })
      }))
    }

    sortedItems?.items[0]?.apportions?.forEach((apportion: any, index: any) => {
      let totalValue = 0;

      sortedItems?.items?.forEach((item: any) => {
          item.apportions.forEach((apport: any) => {
              if (apport.dueDate === apportion.dueDate) {
                  totalValue += apport.value;
              }
          });
      });
      
      let latestColor = getStatusColor(apportion.status.length ? getlatestStatus(apportion.status)[0].statusNF : false)
      // let latestColor = getStatusColor(apportion.status.length ? apportion.status[0].statusNF : false)

      columnsInstallment.push({
        // title: `${index + 1}° Parcela \n ${formatDateToView(apportion.dueDate)} ${formatCurrency(totalValue)}`,
        title: `Parcela ${index + 1} / N° de pedido`,
        dataIndex: `${index + 1}installment`,
        render: (apportion: any, record: any) => {
          return <span style={{color:latestColor}}>{formatCurrency(record?.apportions[index]?.value)} / {record?.apportions[index]?.idErpCorrelation ?? '-'}</span>
        },
        color: latestColor
      });

      rowSomatory.push({
        title: `Total parcela ${index + 1} | Data de vencimento`,
        dataIndex: `${index + 1}installmentTotal`,
        value: `${formatCurrency(totalValue)} | ${formatDateToView(apportion.dueDate)}`,
        info: apportion.status?.length ? `${getStatusText(getlatestStatus(apportion.status)[0].statusNF)} em ${formatDateToView(getlatestStatus(apportion.status)[0].dueDate)} ` : ``,
        color: latestColor
      })
    });

    const updatedColumns = [
      ...columnsApportionmentWithInstallment,
      ...columnsInstallment,
      {
        // title: `Total ${formatCurrency(
        //   sortedItems?.items?.reduce((sum: any, item: any) => sum + 
        //     item.apportions?.reduce((soma: any, valorAtual: any) => soma + valorAtual?.value, 0)
        //     , 0)
        //   )}`,
        title: `Total`,
        dataIndex: "totalValue",
        render: (_: any, record: any) => {
          return <span>{formatCurrency(record?.apportions?.reduce((soma: any, valorAtual: any) => soma + valorAtual?.value, 0))}</span>
        }
      },
    ]

    setColumnsInstallment(updatedColumns)
      
    let mappedRecord: any = []

    sortedItems?.items?.forEach((item: any) => {

      mappedRecord = [ ...mappedRecord, {
        projectIdERP: item?.projectIdErp,
        projectName: item?.projectName,
        // lineIdErp: `${item?.projectRevisionItem?.lineIdErp} - ${item?.projectRevisionItem?.description}`,
        // percentage: item?.percentage,
        apportions: item.apportions,
        status: null
      }
    ]});

    setItems(mappedRecord);

    const summaryItems = [
      ...rowSomatory,
      {
        title: `Total Geral`,
        dataIndex: "finalTotalValue",
        value: `${formatCurrency(
              sortedItems?.items?.reduce((sum: any, item: any) => sum + 
                item.apportions?.reduce((soma: any, valorAtual: any) => soma + valorAtual?.value, 0)
                , 0)
        )}`
      }];

    setSummaryItems(summaryItems)
  }

  const initialValues = {};

  useEffect(() => {
    if (isFirstRender.current) {
      startedOpen()
      isFirstRender.current = false;
      return;
    }
    if(toggle === data?.orderId) {
      setIsOpen(true)
    }
  }, [toggle])

  useEffect(() => {
    getApportionmentInformation();
  }, [isOpen]);

  return (
    <Container>
      <Header onClick={handleOpen}>
        <text>Informações do Rateio e cronograma de pagamento</text>
        {isOpen ? <IoIosArrowDown /> : <IoIosArrowBack />}
      </Header>
      {isOpen && (
        <Content style={{maxWidth:"100%"}}>
          <List size="small">
            <Form form={form} layout="vertical" initialValues={initialValues}>
              <Table
                  // scroll={{ x: '100%'  }}
                  scroll={{ x: 'max-content'  }}
                  columns={columnsInstallment}
                  dataSource={items}
                  pagination={false}
                  size="large"
                  summary={() => {
                    const tableSummary: any = (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0}>Total geral | Vencimento</Table.Summary.Cell>
                          {summaryItems.map((element:any, index:any) => (
                            <Table.Summary.Cell key={index} index={index + 1}>
                              <span style={{color:element.color, display:"flex", alignItems:"center"}}>
                                {element.value} {element.info ? (
                                  <Tooltip title={element.info}>
                                    <svg width={21} height={21} style={{ marginTop: 0, marginLeft: 3 }} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Frame">
                                    <path id="Vector" d="M8 6V8.5M14 8C14 8.78793 13.8448 9.56815 13.5433 10.2961C13.2417 11.0241 12.7998 11.6855 12.2426 12.2426C11.6855 12.7998 11.0241 13.2417 10.2961 13.5433C9.56815 13.8448 8.78793 14 8 14C7.21207 14 6.43185 13.8448 5.7039 13.5433C4.97595 13.2417 4.31451 12.7998 3.75736 12.2426C3.20021 11.6855 2.75825 11.0241 2.45672 10.2961C2.15519 9.56815 2 8.78793 2 8C2 6.4087 2.63214 4.88258 3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8ZM8 10.5H8.00533V10.5053H8V10.5Z" stroke={element.color} stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                    </svg>
                                  </Tooltip>
                                  ) : ``}
                              </span>
                            </Table.Summary.Cell>
                          ))}
                        </Table.Summary.Row>
                      </>
                    );
                    return tableSummary
                  }}
              />
            </Form>
          </List>
        </Content>
      )}
    </Container>
  );
};

export default ApportionmentInformation;
