import React, { useEffect, useMemo, useState } from 'react'
import type { ColumnsType, TableProps } from 'antd/lib/table'
import {
  Button,
  Input,
  Table,
  Tag
} from 'antd'
import { Navigate, useNavigate } from 'react-router'
import {
  PageHeader,
  Section
} from 'components'
import { useBusinessUnits } from 'repositories'
import {
  usePagination,
  useAuth
} from 'hooks'
import { cnpj } from 'cpf-cnpj-validator'
import { SorterResult } from 'antd/lib/table/interface'
import { FilterContainer } from './styles'

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'ID TOTVS',
    dataIndex: 'idERP',
    key: 'idERP',
    sorter: true
  },
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
    sorter: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sorter: true,
    render: (_: any, record: any) => {
      if (record.status) return <Tag color='green'>Ativo</Tag>

      return <Tag color='red'>Inativo</Tag>
    },
  },
  // {
  //   title: 'Cnpj',
  //   dataIndex: 'cnpj',
  //   key: 'cnpj',
  //   sorter: true
  // },
  // {
  //   title: 'Razão Social',
  //   dataIndex: 'corporateName',
  //   key: 'corporateName',
  //   sorter: true
  // },
  // {
  //   title: 'Status',
  //   dataIndex: 'status',
  //   key: 'status',
  //   sorter: true,
  //   render: (_, record) => {
  //     if (record.status) return <Tag color='green'>Ativo</Tag>

  //     return <Tag color='red'>Inativo</Tag>
  //   },
  // }
]

const BusinessUnits = () => {
  const [clients, setClients] = useState<any>([])
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState('')

  const { roles: authRoles, hasRole } = useAuth()
  const repository = useBusinessUnits()
  const navigate = useNavigate()
  const pagination = usePagination()

  const requestBusinessUnits = async () => {
    const currentPage = pagination.data.current ?? 0
    const params = {
      name: search,
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: pagination.data.pageSize ?? 1,
      sort
    }

    const response = await repository.findBus(params)
    if (!response) return

    const mappedData = response.content.map((client: any) => ({
      key: String(client.id),
      id: String(client.id),
      idERP: client.idERP,
      name: client.name,
      classe: '',
      item: '',
      plano: '',
      cond: '',
      status: client.status
    }))
    setClients(mappedData)
    pagination.changeTotalPages(response.totalPages)
  }

  const handleChange: any['onChange'] = (paginationConfig: any, filters: any, sorter: any) => {
    pagination.onChangeTable(paginationConfig)
    const { field, order } = sorter
    if (!field) return

    const dir = order === 'ascend' ? 'ASC' : 'DESC'
    setSort(`${field},${dir}`)
  }

  useEffect(() => {
    if (pagination.data.current) requestBusinessUnits()
  }, [pagination.data.current, pagination.data.pageSize, sort, search])

  const onClickGoToView = (id: number) => () => navigate(`/unidades-de-negocio/visualizar/${id}`)

  const updatedColumns = useMemo(() => [
    ...columns,
    {
      title: '',
      key: 'action',
      render: (_: any, record: any) => (
        <Button
          onClick={onClickGoToView(record.id)}
          disabled={!hasRole(authRoles?.client_view)}
          size='small'
          type='text'
        >
          Visualizar
        </Button>
      ),
    }
  ], [authRoles])

  const onSearch = (value: string) => {
    setSearch(value)
    pagination.onChange(1, pagination.data.pageSize ?? 1)
  }

  if (authRoles && !hasRole(authRoles?.client_view)) {
    return <Navigate to='/acesso-negado' />
  }

  return (
    <>
      <PageHeader
        title='Unidades de Negócio'
        breadcrumbItems={['Unidades de Negócio', 'Listar']}
      />
      <Section>
        <FilterContainer>
          <Input.Search
            placeholder='Pesquise pelo nome'
            onSearch={onSearch}
            enterButton
          />
        </FilterContainer>
        <Table
          size='middle'
          onChange={handleChange}
          loading={repository.loading}
          columns={updatedColumns}
          dataSource={clients}
          pagination={pagination.data}
          showSorterTooltip={false}
        />
      </Section>
    </>
  )
}

export default BusinessUnits
