import React, { useEffect, useState } from 'react'
import { v4 as genId } from 'uuid'
import {
  Button,
  Divider,
  Input,
  message,
  Space,
  Table
} from 'antd'
import type { ColumnsType, TableProps } from 'antd/lib/table'
import {
  TProject,
  TProjectItemParams,
  TProjectParams,
  TProjectRevisionParams,
  TRequestItem,
  useProject,
  useProjectItems
} from 'repositories'
import { tableLocale } from 'utils/lang'
import { useNavigate } from 'react-router'
import { formatDateToView } from 'utils/helpers'
import { usePagination } from 'hooks'
import { FilterContainer } from '../styles'

type TProjectData = TProject & {
  key: string
}

const columns: ColumnsType<TProjectData> = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'ID Erp',
    dataIndex: 'idERP',
    key: 'idERP',
  },
  {
    title: 'Projeto',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Fase',
    dataIndex: 'phaseName',
    key: 'phaseName',
  },
  {
    title: 'Cadastrado em',
    dataIndex: 'dateOfSale',
    key: 'dateOfSale',
  },
  {
    title: 'Data da venda',
    dataIndex: 'inclusionDate',
    key: 'inclusionDate',
  },
]

type TProps = {
  setAvailableRequestItems: React.Dispatch<TRequestItem[]>
  onChangeStep(goTo: number): void
}

const RevisionList = (props: TProps) => {
  const {
    setAvailableRequestItems,
    onChangeStep
  } = props
  const [projectId, setProjectId] = useState(0)
  const [projects, setProjects] = useState<TProjectData[]>([])
  const [isLoadingItems, setIsLoadingItems] = useState(false)
  const [search, setSearch] = useState('')
  const projectRepository = useProject()
  const projectItemsRepository = useProjectItems()
  const navigate = useNavigate()
  const pagination = usePagination()

  const requestProjects = async () => {
    const currentPage = pagination.data.current ?? 0
    const params: TProjectParams = {
      name: search,
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: pagination.data.pageSize ?? 1,
      sort: 'inclusionDate,DESC'
    }

    const response = await projectRepository.findProjectByFilter(params)
    if (!response) return

    const mappedData: TProjectData[] = response.content.map(project => ({
      ...project,
      key: String(project.id),
      dateOfSale: formatDateToView(project.dateOfSale),
      inclusionDate: formatDateToView(project.inclusionDate),
    }))
    setProjects(mappedData)
    pagination.changeTotalPages(response.totalPages)
  }

  useEffect(() => {
    if (pagination.data.current) requestProjects()
  }, [pagination.data.current, pagination.data.pageSize, search])

  const getVersions = async (params: TProjectRevisionParams) => {
    setIsLoadingItems(true)
    const response = await projectRepository.findProjectRevisionByFilter(params)
    setIsLoadingItems(false)
    if (!response.length) return []

    return response
  }

  const getItems = async (id: number, revisionId: number) => {
    const params: TProjectItemParams = {
      projectId: id,
      projectRevisionId: revisionId,
      page: 0,
      size: 1000,
      sort: 'sequence,ASC'
    }
    setIsLoadingItems(true)
    const response = await projectItemsRepository.findProjectItemsByFilter(params)
    setIsLoadingItems(false)
    if (!response.length) return []

    return response
  }

  const onClickGoNextStep = async () => {
    const findProject = projects.find(item => item.id === projectId)
    if (!findProject) return

    const params: TProjectRevisionParams = {
      projectIdERP: findProject.idERP,
      page: 0,
      size: 1,
      sort: 'revision,DESC'
    }

    const versions = await getVersions(params)
    if (!versions?.length) {
      message.info('Não existe revisões cadastradas para esse projeto')
      return
    }

    const [version] = versions

    const items = await getItems(version.project.id, version.id)
    if (!items?.length) {
      message.info('Não existe items cadastrados para essa versão')
      return
    }

    const [item] = items

    const mappedToRequestItems = item.items.map(row => ({
      key: genId(),
      productId: row.productId,
      productName: row.productDescription,
      projectId: findProject.id,
      projectName: findProject.name,
      projectRevisionItemId: row.id,
      categories: row.categories,
      unitSale: row.unitSale,
      unitCost: row.unitCost,
      totalSalesWithoutTax: row.unitSale * row.amount,
      amount: row.amount,
      daily: row.daily
    }) as TRequestItem)

    setAvailableRequestItems(mappedToRequestItems)
    onChangeStep(1)
  }

  const handleChange: TableProps<TProjectData>['onChange'] = paginationConfig => {
    pagination.onChangeTable(paginationConfig)
  }

  const onSearch = (value: string) => setSearch(value)

  const onClickGoBack = () => navigate('/requisicao-compras')

  const rowSelection = {
    onChange: (_: React.Key[], selectedRow: TProjectData[]) => {
      const [row] = selectedRow
      setProjectId(row.id)
    }
  }

  return (
    <>
      <Divider />
      <FilterContainer>
        <Input.Search
          placeholder='Pesquise pelo nome'
          onSearch={onSearch}
          enterButton
        />
      </FilterContainer>
      <Table
        size='middle'
        locale={tableLocale}
        loading={projectRepository.loading}
        columns={columns}
        dataSource={projects}
        onChange={handleChange}
        pagination={pagination.data}
        rowSelection={{
          type: 'radio',
          ...rowSelection
        }}
      />
      <Space
        size='small'
        className='mt-1'
      >
        <Button
          type='primary'
          htmlType='submit'
          disabled={!projectId}
          loading={isLoadingItems}
          onClick={onClickGoNextStep}
        >
          Próximo
        </Button>
        <Button
          onClick={onClickGoBack}
          htmlType='button'
        >
          Cancelar
        </Button>
      </Space>
    </>
  )
}

export default RevisionList
