import React, { useCallback, useEffect, useState } from "react";
import {
  Pagination,
  Spin,
  Button,
  Modal as FilterModal,
  Form,
  message, Tooltip,
  Table, Popover as Pop
} from "antd";
import { Section, DateField, SelectField } from "components";
import Row from "components/Grid/Row";
import Col from "components/Grid/Col";
import { Navigate, useSearchParams } from "react-router-dom";
import { theme } from "styles";
import { useAuth, usePagination, useFilters } from "hooks";
import {
  TPurchaseOrder,
  TPurchaseOrderParams,
  usePurchaseOrder,
  TPurchaseOrderItems,
  useSuppliers,
} from "repositories";
import {
  purchaseOrderStatus,
  purchaseOrderStatusColor,
  purchaseOrderStatusTextColor,
  supplierStatus,
} from "utils/constants";
import {
  formatDateToView,
  formatCurrency,
  formatDateToServer,
  removeMaskCnpj,
} from "utils/helpers";

import Timeline from "pages/Tracker/Components/Timeline";
import Popover from "pages/Tracker/Components/Popover";
import {
  Abbreviation,
  AdditionalInfo,
  Box,
  Content,
  Info,
  InfoOrder,
  Label,
  LabelStatus,
  PaginationContainer,
  ButtonBox,
} from "./styles";
import Modal from "../Components/Modal";
import ChatRequisition from "../Components/ChatRequisition";
import { useNavigate } from "react-router";
import FiltersForm from "../Components/FiltersForm";
import useCostCenter from "../../../../repositories/useCostCenter";
import {Tarja} from "../../../ApprovalPanel/Components/OrdersTab/styles";
import { Tag } from "antd"
import moment from "moment";

const columnsApportionmentWithInstallment = [
  {
    title: "Id Projeto",
    dataIndex: "projectIdERP",
  },
  {
    title: "Projeto",
    dataIndex: "projectName",
    onHeaderCell: () => ({
      style: { minWidth: 220 },
    }),
  },
  {
    title: "Linha",
    dataIndex: "lineIdErp",
    onHeaderCell: () => ({
      style: { minWidth: 220 },
    }),
    render: (_: any, record: any) => (
      <Pop content={record?.lineIdErp} title="Linha - Descrição">
        {`${record?.lineIdErp?.substring(0, 22)}...`}
      </Pop>
    ),
  },
  {
    title: "Percentual Rateio (%)",
    dataIndex: "percentage",
    render: (_: any, record: any) => {
      return `${record.percentage?.toFixed(2)} %`;
    },
  },
]

export const getOrderNames = (order: TPurchaseOrder) => {
  const setNames = new Set();
  order.items.forEach((item) => {
    if (order.isRequestNotTiedToProject)
      setNames.add(
        `${item.costCenter?.erpId} - ${item.costCenter?.description}`
      );
    else {
      setNames.add(`${item.projectIdErp} - 
    ${item.projectName} - 
    ${item.clientName}`);
    }
  });

  return setNames as Set<string>;
};

export const getTypeName = (isNotTied: boolean) => {
  if (isNotTied) return "Não vinculado";
  return "Vinculado";
};

export const getTypeNameAbv = (value: String) => {
  if (value === "PLANEJADA") {
    return <Tooltip title="Requisição planejada">
      PL
    </Tooltip>;
  }
  return <Tooltip title="Requisição não planejada">
    NP
  </Tooltip>;
};

export const getTypeColor = (value: String) => {
  if (value === "PLANEJADA") return "blue";
  return "red";
};

const OrderList = () => {
  const [orders, setOrders] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [orderModal, setOrderModal] = useState<TPurchaseOrder | undefined>();
  const [suppliers, setSuppliers] = useState<any>([]);
  const [costCenter, setCostCenter] = useState<any>([]);
  const [isVisibleFilterForm, setIsVisibleFilterForm] = useState(false);
  const supplierRepository = useSuppliers();
  const costCenterRepository = useCostCenter();
  const pagination = usePagination();
  const repository = usePurchaseOrder();
  const filters = useFilters();
  const [isModalFiltersOpen, setIsModalFiltersOpen] = useState(false);
  const { roles: authRoles, hasRole } = useAuth();
  const navigate = useNavigate();
  const [currentRequestModal, setCurrentRequestModal] = useState()
  const [isModalIdsOpen, setIsModalIdsOpen] = useState(false)
  const [columnsInstallment, setColumnsInstallment] = useState<any>([]);
  const [items, setItems] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const paramsUrlId = (searchParams.has("reqId")) ? searchParams.get("reqId") : null;

  const requestsColumns: any = [
    {
      title: "Linha",
      dataIndex: "lineIdErp",
      // render: (_: any, record: any) => {
      //   return record.issueDate
      //     ? moment(record.issueDate).format("DD/MM/YYYY")
      //     : "";
      // },
    },
    {
      title: "Projeto",
      dataIndex: "projectIdERP",
      // render: (_: any, record: any) => {
      //   return record.maturity
      //     ? moment(record.maturity).format("DD/MM/YYYY")
      //     : "";
      // },
    },
    {
      title: "Valor",
      dataIndex: "value",
      render: (_: any, record: any) => {
        return formatCurrency(record?.value)
      }
    },
    {
      title: "%",
      dataIndex: "percentage",
      render: (_: any, record: any) => {
        return `${record.percentage} %`;
      },
    },
    {
      title: "Vencimento",
      dataIndex: "maturityApportion",
      render: (_: any, record: any) => {
        return record?.maturityApportion
          ? moment(record?.maturityApportion).format("DD/MM/YYYY")
          : "";
      }
    }
  ];

  const formatFilter = (_filters: any) => {
    if (_filters.startIssueDate) {
      _filters.startIssueDate = formatDateToServer(_filters.startIssueDate);
    }
    if (_filters.endIssueDate) {
      _filters.endIssueDate = formatDateToServer(_filters.endIssueDate);
    }
    if (_filters.startIssueDate) {
      _filters.startIssueDate = formatDateToServer(_filters.startIssueDate);
    }
    if (_filters.startMaturity) {
      _filters.startMaturity = formatDateToServer(_filters.startMaturity);
    }
    if (_filters.endMaturity) {
      _filters.endMaturity = formatDateToServer(_filters.endMaturity);
    }
    if (_filters.supplierIdCnpj) {
      for (const objeto of suppliers) {
        if (
          removeMaskCnpj(objeto.cnpj) ===
          removeMaskCnpj(_filters.supplierIdCnpj)
        ) {
          _filters.supplierIdCnpj = objeto.value;
        }
      }
    }
    if (_filters.costCenterId) {
      for (const objeto of costCenter) {
        if (objeto.description === removeMaskCnpj(_filters.costCenterId)) {
          _filters.costCenterId = objeto.value;
        }
      }
    }
    if(paramsUrlId !== null) {
      _filters.idRequest = paramsUrlId;
      searchParams.delete("reqId");
      setSearchParams(searchParams);
    }

    return _filters;
  };

  const getOrders = async () => {
    const responseSupplier = await supplierRepository.findSupplierByFilter({
      page: 0,
      size: 10000,
    });
    if (!responseSupplier) return;

    const mappedSupplier = responseSupplier.content.map((item: any) => ({
      value: item.id,
      cnpj: item.cnpj,
    }));

    if (!responseSupplier) return;
    setSuppliers(mappedSupplier);

    const responseCostCenter =
      await costCenterRepository.findByFilterCostCenter({
        page: 0,
        size: 10000,
      });
    if (!responseCostCenter) return;

    const mappedCostCenter = responseCostCenter.content.map((item: any) => ({
      value: item.id,
      description: item.description,
    }));

    if (!responseCostCenter) return;
    setCostCenter(mappedCostCenter);

    const currentPage = pagination.data.current ?? 0;
    const _filters = filters.getObjectifiedFilterItems();
    const formatedFilters = formatFilter(_filters);

    const params: TPurchaseOrderParams = {
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: pagination.data.pageSize ?? 10,
      sort: "id,DESC",
      ...formatedFilters,
    };

    const response = await repository.findPurchaseOrderByFilter(params);
    if (!response) return;
    if (response.content?.length === 0)
      message.warn("Você não possui requisições no momento");

    if (response.totalPages) pagination.changeTotalPages(response.totalPages);
    setOrders(response.content);
  };

  const onClickOpenFilterForm = () => {
    setIsVisibleFilterForm(true);
  };

  const getDatePayment = (items: TPurchaseOrderItems[]) => {
    const dateOneItem = items.find((item: TPurchaseOrderItems) => item);

    if (dateOneItem) {
      return formatDateToView(dateOneItem.startDatePayment);
    }
  };

  useEffect(() => {
    if (pagination.data.current) getOrders();
  }, [pagination.data.current, pagination.data.pageSize, filters.items]);

  useEffect(() => {
    if (!orderModal) return;
    setIsModalOpen(true);
  }, [orderModal]);

  if (authRoles && !hasRole(authRoles?.request_tracking)) {
    return <Navigate to="/acesso-negado" />;
  }

  const getSupplierStatus = (status: any) => {
    switch (status) {
      case "APPROVED":
        return (
          <Label className="label" color="green">
            Homologado
          </Label>
        );
        break;

      case "NOT_APPROVED":
        return (
          <Label className="label" color="red">
            Bloqueado
          </Label>
        );
        break;

      default:
        return (
          <Label className="label" color="blue">
            {supplierStatus[status]}
          </Label>
        );
        break;
    }
  };

  const updateList = () => {
    getOrders();
  };

  const onClickGoToView = (id: any) => {
    window.open(`/requisicao/visualizar/${id}`);
  };
  const resetPagination = () => {
    pagination.onChange(1, pagination.data.pageSize ?? 10);
  };

  const openRequests = (record: any) => {

    const columnsInstallment: any = []

    const sortedItems = {
      ...record,
      items: record?.items?.map((item: any) => ({
        ...item,
        apportions: item?.apportions?.sort((a: any, b: any) => {
          const dateA: any = new Date(a.dueDate);
          const dateB: any = new Date(b.dueDate);
          return dateA - dateB;
        })
      }))
    }

    sortedItems?.items[0]?.apportions?.forEach((apportion: any, index: any) => {

      let totalValue = 0;

      sortedItems?.items?.forEach((item: any) => {
          item.apportions.forEach((apport: any) => {
              if (apport.dueDate === apportion.dueDate) {
                  totalValue += apport.value;
              }
          });
      });

      columnsInstallment.push({
        title: `${index + 1}° Parcela \n ${formatDateToView(apportion.dueDate)} ${formatCurrency(totalValue)}`,
        dataIndex: `${index + 1}installment`,
        render: (apportion: any, record: any) => {
          return <span>{formatCurrency(record?.apportions[index]?.value)}<br />{record?.apportions[index]?.idErpCorrelation}</span>
        }
      });
    });

    const updatedColumns = [
      ...columnsApportionmentWithInstallment,
      ...columnsInstallment,
      {
        title: `Total ${formatCurrency(sortedItems?.items?.reduce((sum: any, item: any) => sum + item.apportions?.reduce((soma: any, valorAtual: any) => soma + valorAtual?.value, 0), 0))}`,
        dataIndex: "totalValue",
        render: (_: any, record: any) => {
          return <span>{formatCurrency(record?.apportions?.reduce((soma: any, valorAtual: any) => soma + valorAtual?.value, 0))}</span>
        }
      },
    ]

    setColumnsInstallment(updatedColumns)
    
    let mappedRecord: any = []

    sortedItems?.items?.forEach((item: any) => {

      mappedRecord = [ ...mappedRecord, {
        projectIdERP: item?.projectIdErp,
        projectName: item?.projectName,
        lineIdErp: `${item?.projectRevisionItem?.lineIdErp} - ${item?.projectRevisionItem?.description}`,
        percentage: item?.percentage,
        apportions: item.apportions
      }]

    });

    setCurrentRequestModal(mappedRecord)
    setItems(record?.items)
    setIsModalIdsOpen(true)
  }

  return (
    <>
      <ButtonBox>
        <Button onClick={onClickOpenFilterForm}>Filtros</Button>
      </ButtonBox>
      <Section>
        <Spin tip="Carregando" spinning={repository.loading}>
          {orders?.map((order: any) => (
            <Box key={order.id}>
              <Content>
                {order.projectRequest.typeRequest === "COMPOSED" ? (
                    <div>
                      <Tarja>
                        Requisição composta [Rateio e Contrato]
                      </Tarja>
                    </div>
                ) : (
                    ""
                )}
                <br/>
                <Info>
                  <div>
                    <div className="wrapper">
                      <LabelStatus color={purchaseOrderStatusColor[order.status]} textColor={purchaseOrderStatusTextColor[order?.status]}>
                        {purchaseOrderStatus[order.status]}
                      </LabelStatus>
                      {/* <Label
                      color={getTypeColor(order.isRequestNotTiedToProject)}
                    >
                      {getTypeName(order.isRequestNotTiedToProject)}
                    </Label> */}
                      {order.projectRequest.typeRequest === "COMPOSED" ? (
                          <Label color="black">Composta</Label>
                      ) : (
                          <Label color="gray">Simples</Label>
                      )}
                      <Label color="gray">Compra e contratação</Label>
                      <Label color="gray">Com projeto</Label>
                      {/*Tag varba so deve aparecer se não for vinculada a projeto*/}
                      {!order?.projectRequest.id ? (
                        <Label color="gray">Verba TV1</Label>
                      ) : (
                        ""
                      )}
                    </div>
                    <Popover setNames={getOrderNames(order)} title="Projetos" />
                  </div>

                  <div style={{ display: "initial", textAlign: "right" }}>
                    <p>{formatCurrency(order?.purchaseValue)}</p>
                    <span>{`Emissão: ${formatDateToView(
                        order?.issueDate
                    )}`}</span>
                    {/*<br />*/}
                    {/*<span>Aprovação: dd/mm/aa</span>*/}
                    <br />
                    {
                      order?.projectRequest?.typeRequest === "COMPOSED" ?
                      <span>{`1º Vencim.: ${formatDateToView(order?.items[0]?.apportions[0]?.dueDate)}`}</span>
                      :
                      <span>{`Vencim.: ${formatDateToView(order?.projectRequest?.maturity)}`}</span>
                    }
                    {/* <span>{`Vencim.: ${formatDateToView(
                        order?.projectRequest?.maturity
                    )}`}</span> */}
                  </div>
                </Info>
                <AdditionalInfo>
                  <div>
                    <Abbreviation>
                      <span>
                        {
                          order?.projectRequest?.items ?
                            order?.projectRequest?.items[0]?.projectRevision
                            ?.project?.buName
                          :
                          <></>
                        }
                      </span>
                      <Label
                        className="label"
                        color={getTypeColor(
                          String(order.approvalHierarchyTiming)
                        )}
                      >
                        {getTypeNameAbv(String(order.approvalHierarchyTiming))}
                      </Label>
                      <span>{order?.projectRequest.id}</span>
                      {
                        order.projectRequest.typeRequest === "COMPOSED" ? 
                          <Tag
                            color={order?.status === 'APPROVED' ? 'green' : 'default'}
                            style={{ cursor: "pointer", marginTop: '10px' }}
                            onClick={() => openRequests(order)}
                          >
                            Múltiplos IDs
                          </Tag>
                        :
                        <span>{order?.orderNumber}</span>
                      }
                    </Abbreviation>
                    <InfoOrder>
                      <span className="productTitle">{order.productName}</span>
                      <div>
                        {/* <Label className="label" color={theme.colors.GREEN}>
                          Homologado
                        </Label> */}
                        <div className="wrapper">
                          <Label className="label" color="gray">
                            {order?.supplierType === "SUPPLIER"
                              ? "Fornecedor"
                              : "Freelancer"}
                          </Label>
                          {getSupplierStatus(order?.supplierStatus)}
                        </div>
                        <span>{order.supplierFantasyName}</span>
                      </div>
                      <div>
                        <Label className="label" color={theme.colors.GREEN}>
                          Requisitante
                        </Label>
                        <p>{order.creationUserName}</p>
                      </div>
                    </InfoOrder>
                  </div>
                  <div style={{paddingTop: '10px'}}>
                    <Modal order={order} updateList={updateList} />
                    <Button
                      onClick={() => {
                        onClickGoToView(order?.projectRequest.id);
                      }}
                    >
                      Ver Detalhes
                    </Button>
                  </div>
                </AdditionalInfo>
              </Content>
              <Timeline order={order} />
            </Box>
          ))}
        </Spin>
      </Section>
      <PaginationContainer>
        <Pagination
          current={pagination.data.current}
          onChange={pagination.onChange}
          total={pagination.data.total}
          pageSize={pagination.data.pageSize}
          showSizeChanger
        />
      </PaginationContainer>
      {/* <Modal
        open={isModalOpen}
        order={orderModal}
        setIsModalVisible={setIsModalOpen}
        setOrderModal={setOrderModal}
      /> */}
      <FiltersForm
        resetPagination={resetPagination}
        isVisibleDrawer={isVisibleFilterForm}
        setIsVisibleDrawer={setIsVisibleFilterForm}
        filters={filters}
      />
      <FilterModal
        title="Pedidos gerados"
        className="horizontal-scrollable-modal"
        open={isModalIdsOpen}
        onOk={() => setIsModalIdsOpen(false)}
        onCancel={() => setIsModalIdsOpen(false)}
        width={1300}
      >
        <Table
          columns={columnsInstallment}
          dataSource={currentRequestModal}
          pagination={false}
          style={{ overflowX: "auto" }}
        />
      </FilterModal>
    </>
  );
};

export default OrderList;
