import React, {
  useEffect,
  useMemo,
  useState
} from 'react'
import useCostCenter from 'repositories/useCostCenter'
import {
  Section,
  PageHeader,
  Row,
  Col,
  TextField,
  CheckField
} from 'components'
import { cnpj } from 'cpf-cnpj-validator'
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams
} from 'react-router'
import {
  Button,
  Form,
  Space,
  Spin
} from 'antd'
import { useAuth } from 'hooks'

const initialValues: any = {
  id: 0,
  erpId: '',
  cnpj: '',
  corporateName: '',
  fantasyName: '',
  email: '',
  responsible: '',
  status: true
}

const ClientForm = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { roles: authRoles, hasRole } = useAuth()
  const { pathname } = useLocation()
  const repository = useCostCenter()
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const [form] = Form.useForm<any>()
  const watchStatus = Form.useWatch('status', form)

  useEffect(() => {
    const getClient = async () => {
      if (!id) return
      const response = await repository.findCostCenterById(id)
      // console.log(response)
      if (!response) return

      const data: any = {
        erpId: response.erpId,
        nome: response.description,
        classe: response.classe,
        idBU: response.idBUErp,
        descricaoBU: response.descriptionBU,
        classificacao: response.classification,
        status: response.status ? 'Ativo' : 'Inativo'
      }
      form.setFieldsValue(data);
      setIsLoading(false);
    }

    form.resetFields()
    getClient()
  }, [id])

  const onFinish = (values: any) => {
    console.warn(values)
  }

  const onClickGoBack = () => {
    navigate('/centros-de-custo')
  }

  const {
    title,
    isView
  } = useMemo(() => {
    const [, , page] = pathname.split('/')
    const getTitle = () => {
      if (page === 'visualizar') return 'Visualizar'
      if (id) return 'Editar'

      return 'Nova'
    }

    return {
      title: getTitle(),
      isView: page === 'visualizar'
    }
  }, [id])

  if (authRoles && !hasRole(authRoles?.client_edit || authRoles?.client_view)) {
    return <Navigate to='/acesso-negado' />
  }

  return (
    <>
      <PageHeader
        title='Centro de Custo'
        breadcrumbItems={['Centro de Custo', title, String(id)]}
      />
      <Section spacedTop>
        <Spin spinning={isLoading}>
          <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={initialValues}
          >
            <Row>
              <Col xl={4}>
                <TextField
                  name='erpId'
                  label='Id ERP'
                  disabled={isView}
                />
              </Col>
              <Col xl={12}>
                <TextField
                  name='nome'
                  label='Nome do Centro de Custo'
                  disabled={isView}
                />
              </Col>
            </Row>
            <Row>
              <Col xl={4}>
                <TextField
                  name='classe'
                  label='Classe'
                  disabled={isView}
                />
              </Col>
              <Col xl={4}>
                <TextField
                  name='idBU'
                  label='ID BU'
                  disabled={isView}
                />
              </Col>
              <Col xl={4}>
                <TextField
                  name='descricaoBU'
                  label='Descrição BU'
                  disabled={isView}
                />
              </Col>
              <Col xl={4}>
                <TextField
                  name='classificacao'
                  label='Classificação'
                  disabled={isView}
                />
              </Col>
            </Row>
            <Row>
              <Col xl={4}>
                <TextField
                  name='status'
                  label='Status'
                  disabled={isView}
                />
              </Col>
            </Row>
            <Form.Item>
              <Space size='small'>
                <Button
                  onClick={onClickGoBack}
                  htmlType='button'
                >
                  Voltar
                </Button>
                {/* <Button
                disabled={isView}
                type='primary'
                htmlType='submit'
              >
                Salvar
              </Button> */}
              </Space>
            </Form.Item>
          </Form>
        </Spin>
      </Section>
    </>
  )
}

export default ClientForm
