import React, { useEffect, useMemo, useState } from "react";
import { Descriptions, Divider, PageHeader as Header, Tabs, Tag } from "antd";
import { Navigate, useNavigate, useParams } from "react-router";
import { DetailGrid, PageHeader, Section } from "components";
import {
  TProjectDetail,
  TProjectRevision,
  TProjectRevisionParams,
  useProject,
} from "repositories";
import { formatDateToView } from "utils/helpers";
import { cnpj } from "cpf-cnpj-validator";
import { useAuth } from "hooks";
import VersionList from "../Components/VersionList";
import AvatarGroup from '../Components/AvatarGroup'

const { TabPane } = Tabs;

const ProjectDetail = () => {
  const [projectDetail, setProjectDetail] = useState<any>(
    {} as TProjectDetail
  );
  const [versions, setVersions] = useState<TProjectRevision[]>([]);
  const [version, setVersion] = useState<TProjectRevision>(
    {} as TProjectRevision
  );

  const { roles: authRoles, hasRole } = useAuth();
  const repository = useProject();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const getVersions = async () => {
      const params: TProjectRevisionParams = {
        projectIdERP: projectDetail.idERP,
        page: 0,
        size: 100,
        sort: "revision,DESC",
      };

      const response = await repository.findProjectRevisionByFilter(params);
      if (!response.length) return;

      const lastVersion = response[0];
      setVersions(response);
      setVersion(lastVersion);
    };

    if (projectDetail.idERP) getVersions();
  }, [projectDetail]);

  useEffect(() => {
    const getProject = async () => {
      const response = await repository.findProjectById(String(id));
      if (!response) return;

      let companyName = ''
      let companyCnpj = ''

      const BU = response.idERP.slice(0, 3)

      if (response?.buId?.idERP === 'BU02') {
        switch (BU) {
          case '09U':
            companyName = 'Empresa Brasileira de Comunicação Produção Ltda'
            companyCnpj = '04.405.242/0001-16'
            break;

          case "09M":
            companyName = 'EMCI agência de Viagens Turismo e Incentivos Ltda'
            companyCnpj = '17.280.120/0001-92'
            break;
        }
      } else {
        switch (response?.buId?.idERP) {
          case 'BU01':
          case "BU05":
            companyName = 'Empresa Brasileira de Comunicação Produção Ltda'
            companyCnpj = '04.405.242/0001-16'
            break;

          case "BU06":
            companyName = 'Empresa Latino Americana de Soluções Digitais Ltda'
            companyCnpj = '47.489.881/0001-10'
            break;

          case "BU03":
            companyName = 'EMCI agência de Viagens Turismo e Incentivos Ltda'
            companyCnpj = '17.280.120/0001-92'
            break;

          default:
            companyName = 'Empresa Brasileira de Comunicação Produção Ltda'
            companyCnpj = '04.405.242/0001-16'
            break;
        }
      }

      const data: TProjectDetail = {
        ...response,
        inclusionDate: formatDateToView(response.inclusionDate),
        dateOfSale: formatDateToView(response.dateOfSale),
        company: {
          ...response.company,
          // cnpj: cnpj.format(response.company.cnpj),
          name: companyName,
          cnpj: companyCnpj,
        },
        client: {
          ...response.client,
          cnpj: cnpj.format(response.client.cnpj),
        },
      };

      setProjectDetail(data);









    };

    if (id) getProject();
  }, [id]);

  const onClickGoBack = () => navigate("/projetos");

  const international = useMemo(
    () => (projectDetail.internationalCurrency ? "Sim" : "Não"),
    [projectDetail]
  );

  const clientStatus = useMemo(() => {
    if (projectDetail.client?.status) {
      return <Tag color="green">Ativo</Tag>;
    }

    return <Tag color="red">Inativo</Tag>;
  }, [projectDetail]);

  if (authRoles && !hasRole(authRoles?.project_view)) {
    return <Navigate to="/acesso-negado" />;
  }

  return (
    <>
      <PageHeader
        title="Detalhes do Projeto"
        breadcrumbItems={["Projetos", "Detalhes", String(id)]}
      />
      <Header onBack={onClickGoBack} title={projectDetail.name} />
      <Section>
        <DetailGrid>
          <Descriptions.Item label="Id ERP">
            {projectDetail.idERP}
          </Descriptions.Item>
          <Descriptions.Item label="Unidade de Negócio">
            {projectDetail.buId?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Nome do projeto">
            {projectDetail.name}
          </Descriptions.Item>
          <Descriptions.Item label="Tipo do projeto">
            {projectDetail?.projectType?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Criado em">
            {projectDetail.inclusionDate}
          </Descriptions.Item>
          <Descriptions.Item label="Tipo">
            {projectDetail.projectType?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Data da venda">
            {projectDetail.dateOfSale}
          </Descriptions.Item>
          <Descriptions.Item label="Status do projeto">
            {version?.project?.status ?
              <Tag color="green">Ativo</Tag>
              :
              <Tag color="red">Inativo</Tag>
            }
          </Descriptions.Item>
          {/*<Descriptions.Item label="Fase TOTVS">*/}
          {/*  {projectDetail.phaseTotvs?.name}*/}
          {/*</Descriptions.Item>*/}
          {/*<Descriptions.Item label="Fase CRM">*/}
          {/*  {projectDetail.phaseCrm?.name}*/}
          {/*</Descriptions.Item>*/}
          <Descriptions.Item label="Categoria do Projeto">
            {projectDetail.projectCategory?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Internacional">
            {international}
          </Descriptions.Item>
        </DetailGrid>

        <Divider plain orientation="left">
          Responsáveis
        </Divider>
        <DetailGrid>


          <Descriptions.Item contentStyle={{ paddingLeft: '20px' }}>
            <AvatarGroup
              users={[
                projectDetail.userAccountLeader,
                projectDetail.userBuDirector,
                projectDetail.userOperationsLeader,
                projectDetail.userCeo
              ]}
              rightOffset={-100}
            />
          </Descriptions.Item>
        </DetailGrid>
        <Divider plain orientation="left">
          Empresa de Faturamento
        </Divider>
        <DetailGrid>
          <Descriptions.Item label="CNPJ">
            {projectDetail.company?.cnpj}
          </Descriptions.Item>
          <Descriptions.Item label="Razão Social">
            {projectDetail.company?.name}
          </Descriptions.Item>
          {/* <Descriptions.Item label="Filial">
            {projectDetail.subsidiary?.name}
          </Descriptions.Item> */}
        </DetailGrid>
        <Divider plain orientation="left">
          Cliente
        </Divider>
        <DetailGrid>
          <Descriptions.Item label="CNPJ">
            {projectDetail.client?.cnpj}
          </Descriptions.Item>
          <Descriptions.Item label="Razão Social">
            {projectDetail.client?.corporateName}
          </Descriptions.Item>
          <Descriptions.Item label="Nome Fantasia">
            {projectDetail.client?.fantasyName}
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            {projectDetail.client?.email}
          </Descriptions.Item>
          <Descriptions.Item label="Status do cliente">{clientStatus}</Descriptions.Item>
        </DetailGrid>
        {/* <Tabs
          onChange={onChangeVersion}
          className='mt-1'
          type='card'
        >
          {versions.map(item => {
            const labelTab = `${item.revision} (${item.type})`
            return (
              <TabPane
                tab={labelTab}
                key={item.id}
              />
            )
          })}
        </Tabs> */}
        <VersionList version={version} />
      </Section>
    </>
  );
};

export default ProjectDetail;
