import React, { useEffect, useMemo, useState } from "react";
import { Button, Dropdown, Menu } from "antd";
import { BiChevronDown } from "react-icons/bi";
import { TGroupMemberChat, TMemberChat, TPurchaseOrder } from "repositories";
import type { ItemType } from "antd/lib/menu/hooks/useItems";
import Chat from "pages/Components/Chat";
import { Container, Flex } from "./styles";
import { useAuth } from "hooks";
import { AiOutlineBell } from "react-icons/ai";

const ChatRequisition = (props: any) => {
  const { purchaseOrderId, groupMembers, order, updateList, layoutApproval } =
    props;
  const [isVisible, setIsVisible] = useState(false);
  const [newMessage, setNewMessage] = useState(false);
  const { userData } = useAuth();

  const openChat = () => {
    setNewMessage(false);
    setIsVisible(true);
    updateList();
  };

  const disableMessages = () => {
    if(userData?.authorities?.includes("ROLE_ADMIN")) {
      return false
    }
    return !order?.isInRequest
  }

  useEffect(() => {
    if(order?.chat?.length > 0 && order?.chat[0]?.messagesNotViewed > 0){
      setNewMessage(true)
    }
  }, [order])

  return (
    <Container>
      {layoutApproval ? (
        <Button className={"ant-btn-question " + (newMessage ? "new-question" : "")} onClick={() => openChat()} disabled={disableMessages()}>
          <Flex style={{ alignItems: "center" }}>
            <span>Questionar</span>
            {newMessage ? (
              <img
                src="/exclamation.svg"
                alt=""
                width="16px"
                style={{ marginTop: "0px", marginLeft: "-5px" }}
              />
            ) : (
              <img
                src="/notify.svg"
                alt=""
                width="16px"
                style={{ marginTop: "0px", marginLeft: "-5px" }}
              />
            )}
          </Flex>
        </Button>
      ) : ( 
        <Button className={"ant-btn-question " + (newMessage ? "new-question" : "")} onClick={() => openChat()} disabled={disableMessages()}>
          <Flex style={{ alignItems: "center" }}>
            <span>Questionar</span>
            {newMessage ? (
              <img
                src="/exclamation.svg"
                alt=""
                width="16px"
                style={{ marginTop: "0px", marginLeft: "-5px" }}
              />
            ) : (
              <img
                src="/notify.svg"
                alt=""
                width="16px"
                style={{ marginTop: "0px", marginLeft: "-5px" }}
              />
            )}
          </Flex>
        </Button>
      ) }
      <Chat
        isVisible={isVisible}
        purchaseOrderId={purchaseOrderId}
        setIsVisible={setIsVisible}
        groupMembers={groupMembers}
        order={order}
      />
    </Container>
  );
};

export default ChatRequisition;
