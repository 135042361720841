import useRest from "hooks/useRest";
import { useState } from "react";
import {
  TSupplierCreateUserRequest,
  TFindUserParams,
  TUser,
  TUserRequest,
  TUserResponse,
  TProfileForm,
} from ".";

const useUsers = () => {
  const [loading, setLoading] = useState(false);
  const rest = useRest();

  const findByFilterUsers = async (params: any, triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/system-user";
    const response = await rest.get(url, params);
    if (triggerLoading) setLoading(false);
    if (!response) return undefined;

    return response.result as TUserResponse;
  };

  const findUserById = async (id: number, triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = `/v1/system-user/${id}`;
    const response = await rest.get(url);
    if (triggerLoading) setLoading(false);
    if (!response) return {};

    return response.result;
  };

  const findUserHistoryById = async (id: number, triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = `/v1/audit/user/logs-replacement-justification?user_id=${id}`;
    const response = await rest.get(url);
    if (triggerLoading) setLoading(false);
    if (!response) return {};

    return response.result;
  };

  const createUserToSupplier = async (
    user: TSupplierCreateUserRequest,
    triggerLoading = true
  ) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/system-user/supplier";
    const response = await rest.post(url, user);
    if (triggerLoading) setLoading(false);
    if (!response) return;

    return response.result as TUser;
  };

  const updateUser = async (user: TUserRequest, triggerLoading = true) => {
    if (triggerLoading) setLoading(true);
    const url = "/v1/system-user";
    const response = await rest.put(url, user);
    if (triggerLoading) setLoading(false);
    if (!response) throw new Error();

    return response.result as TUser;
  };

  return {
    loading,
    findUserById,
    findByFilterUsers,
    createUserToSupplier,
    updateUser,
    findUserHistoryById
  };
};

export default useUsers;
