import styled from "styled-components";

export const Box = styled.div`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.WHITE};
  border: ${({ theme }) => theme.borders.SOLID_LIGHT_GRAY};
  border-radius: 4px;
  padding: 6px 0;
  margin-top: 10px;

  > div {
    padding: 5px 15px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.XL}) {
    flex-direction: column;
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 5px 15px 0 5px;
`;

type TLabelProps = {
  color: string;
};

interface LabelProps {
  textColor: string;
}

export const Label = styled.span<TLabelProps>`
  font-size: 10px;
  padding: 4px 10px;
  color: ${({ theme }) => theme.colors.WHITE};
  background-color: ${(p) => p.color};
  border-radius: 4px;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.HD}) {
    font-size: 9px;
  }
`;

export const LabelStatus = styled.span.attrs((props: LabelProps) => ({
  textColor: props.textColor,
}))<LabelProps>`
  font-size: 10px;
  padding: 4px 10px;
  color: ${props => props.textColor};
  background-color: ${(p) => p.color};
  border-radius: 4px;
  cursor: default;

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.HD}) {
    font-size: 9px;
  }
`;

export const Content = styled.div`
  width: 40%;
  border-right: ${({ theme }) => theme.borders.SOLID_LIGHT_GRAY};

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.XL}) {
    width: 100%;
    border-right: none;
    border-bottom: ${({ theme }) => theme.borders.SOLID_LIGHT_GRAY};
  }
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    margin: 0;
  }

  > div div.wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: flex-start;
    height: min-content;
    margin-bottom: 10px;
  }

  > div span {
    
  }

  > div:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 140px;

    p {
      font-size: 20px;
    }

    span {
      font-size: 10px;
      line-height: 14px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.HD}) {
    > div:last-child p {
      font-size: 16px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.SM}) {
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    font-size: 12px;
    margin-bottom: 10px;

    > div:last-child {
      align-items: flex-start;

      p {
        font-size: 14px;
      }
    }
  }
`;

export const AdditionalInfo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  > div {
    display: flex;
    gap: 10px;
  }

  > div:last-child {
    align-self: flex-end;
    font-size: 12px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.HD}) {
    flex-direction: column;
  }
`;

export const InfoOrder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  p {
    font-size: 16px;
    margin: 0;
  }

  span:not(.label) {
    font-size: 16px;
  }

  span.label {
    padding: 5px 15px;
  }

  div {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  span.productTitle {
    font-size: 20px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakPoints.HD}) {
    span:not(.label) {
      font-size: 12px;
    }

    p {
      font-size: 16px;
    }
  }
`;

export const Abbreviation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > span {
    font-size: 10px;
    color: ${({ theme }) => theme.colors.BLACK};
  }

  span.label {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.WHITE};
  }
`;
export const PaginationContainer = styled.div`
  display: flex;
  padding: 0 15px 15px 0;
  flex-direction: row;
  justify-content: flex-end;
`;
