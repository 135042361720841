import React, { useEffect, useMemo, useState } from "react";
import { Input, message, Table, Tag } from "antd";
import { Navigate, useNavigate } from "react-router";
import { DropdownButton, PageHeader, Section } from "components";
import {
  TFindUserParams,
  TGroup,
  TUser,
  TUserRequest,
  useUserGroups,
  useUsers,
} from "repositories";
import { usePagination, useAuth } from "hooks";
import type { ColumnsType, TableProps } from "antd/lib/table";
import type { SorterResult } from "antd/lib/table/interface";
import { Status, UserStatus } from "utils/constants";
import { tableLocale } from "utils/lang";
import { FilterContainer } from "./styles";
import ChangeGroupForm from "../Components/ChangeGroupForm";

// const { roles: authRoles, hasRole } = useAuth();

type TUserData = Pick<TUser, "id" | "name" | "email" | "status"> & {
  key: string;
  groupId: number;
  groupName: string;
  functionId: number;
  areaId: number;
  picture?: string;
  telephone?: string;
  supplierId: number;
};

const columns: ColumnsType<TUserData> = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Nome",
    dataIndex: "name",
    key: "name",
    sorter: true,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    sorter: true,
  },
  {
    title: "Papel",
    dataIndex: "role",
    key: "role",
    sorter: true,
  },
  {
    title: "Grupo",
    dataIndex: "userGroup",
    key: "userGroup",
    sorter: true,
    defaultSortOrder: "ascend",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: true,
    render: (_, record) => {
      if (record.status === UserStatus.ACTIVATED)
        return <Tag color="green">Ativo</Tag>;

      return <Tag color="red">Inativo</Tag>;
    },
  },
];

const UsersList = () => {
  const [userId, setUserId] = useState(0);
  const [groups, setGroups] = useState<TGroup[]>([]);
  const [users, setUsers] = useState<TUserData[]>([]);
  const [isVisibleChangeGroupForm, setIsVisibleChangeGroupForm] =
    useState(false);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("userGroup,ASC");
  const [valueGroup, setValueGroup] = useState(0);

  const { roles, hasRole } = useAuth();
  const repository = useUsers();
  const userGroupRepository = useUserGroups();
  const navigate = useNavigate();
  const pagination = usePagination();

  const requestUserGroups = async () => {
    const response = await userGroupRepository.findGroupsByFilter({
      groupStatus: Status.ACTIVE,
      size: 1000,
      page: 0,
    });
    if (!response) return;

    setGroups(response.content);
  };

  const requestUser = async () => {
    const currentPage = pagination.data.current ?? 0;
    const params: TFindUserParams = {
      name: search,
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: pagination.data.pageSize ?? 1,
      sort: sort,
    };

    const response = await repository.findByFilterUsers(params);
    if (!response) return;

    const mappedData: any[] = response.content.map((user) => ({
      key: String(user.id),
      id: user.id,
      name: user.name,
      email: user.email,
      groupId: user.userGroup?.id,
      role: user.userFunction?.name,
      userGroup: user.userGroup?.name,
      status: user.status,
      areaId: user.userArea?.id ?? null,
      functionId: user.userFunction?.id ?? null,
      telephone: user.telephone,
      picture: user.picture,
      supplierId: Number(user.supplierId),
    }));
    setUsers(mappedData);
    pagination.changeTotalPages(response.totalPages);
  };

  const handleChange: TableProps<TUserData>["onChange"] = (
    paginationConfig,
    filters,
    sorter
  ) => {
    pagination.onChangeTable(paginationConfig);
    const { field, order } = sorter as SorterResult<TUserData>;
    if (!field) return;

    const dir = order === "ascend" ? "ASC" : "DESC";
    setSort(`${field},${dir}`);
  };

  useEffect(() => {
    if (pagination.data.current) requestUser();
  }, [pagination.data.current, pagination.data.pageSize, sort, search]);

  useEffect(() => {
    if (roles && hasRole(roles?.group_view)) {
      requestUserGroups();
    }
  }, [roles]);

  const onClickGoToView = (id: number) => () =>
    navigate(`/usuarios/visualizar/${id}`);
  const onClickGoToViewEdit = (id: number) => () =>
    navigate(`/usuarios/editar/${id}`);

  const onClickChangeGroup = (record: TUserData) => () => {
    setValueGroup(record.groupId);
    setIsVisibleChangeGroupForm(true);
    setUserId(record.id);
  };

  const onClickChangeStatus = (record: TUserData) => async () => {
    const status =
      record.status === UserStatus.ACTIVATED
        ? UserStatus.DEACTIVATED
        : UserStatus.ACTIVATED;
    const data: TUserRequest = {
      ...record,
      userGroupId: record.groupId ?? null,
      status,
    };
    try {
      await repository.updateUser(data);
      requestUser();
      message.success("Status do usuário atualizado com sucesso!");
    } catch (error) {}
  };

  const updatedColumns = useMemo(
    () => [
      ...columns,
      {
        title: "",
        key: "action",
        render: (_, record) => {
          const disableButtonChangeGroup =
            !hasRole(roles?.group_view) ||
            !hasRole(roles?.user_edit) ||
            record.supplierId;
          const itemsMenu = [
            {
              key: "1",
              label: "Visualizar",
              onClick: onClickGoToView(record.id),
            },
            {
              key: "2",
              label: "Editar",
              onClick: onClickGoToViewEdit(record.id),
              disabled: !hasRole(roles?.user_edit),
            },
            {
              key: "3",
              label:
                record.status === UserStatus.ACTIVATED ? "Inativar" : "Ativar",
              disabled: !!record.supplierId || !hasRole(roles?.user_edit),
              onClick: onClickChangeStatus(record),
            },
            {
              key: "4",
              label: "Alterar Grupo",
              disabled: !!disableButtonChangeGroup,
              onClick: onClickChangeGroup(record),
            },
          ];

          return <DropdownButton items={itemsMenu} />;
        },
      },
    ],
    [roles]
  );

  const onSearch = (value: string) => {
    setSearch(value);
    pagination.onChange(1, pagination.data.pageSize ?? 1)
  }

  if (roles && !hasRole(roles?.user_view)) {
    return <Navigate to="/acesso-negado" />;
  }

  return (
    <>
      <PageHeader title="Usuários" breadcrumbItems={["Usuários", "Listar"]} />
      <Section>
        <FilterContainer>
          <Input.Search
            placeholder="Pesquise pelo nome"
            onSearch={onSearch}
            enterButton
          />
        </FilterContainer>
        <Table
          size="middle"
          locale={tableLocale}
          onChange={handleChange}
          loading={repository.loading}
          columns={updatedColumns}
          dataSource={users}
          pagination={pagination.data}
          showSorterTooltip={false}
        />
        <ChangeGroupForm
          userId={userId}
          groups={groups}
          value={valueGroup}
          isVisible={isVisibleChangeGroupForm}
          setIsVisible={setIsVisibleChangeGroupForm}
          requestFn={requestUser}
        />
      </Section>
    </>
  );
};

export default UsersList;
